import * as yup from 'yup';
import * as immutable from 'immutable';

const RatingItemRecord = immutable.Record({
    score: null,
    count: 0,
});

const RatingCourseRecord = immutable.Record({
    id: null,
    title: null,
    group: null,
});

export class RatingItem extends RatingItemRecord {}

export class RatingCourse extends RatingCourseRecord {}

export const RatingItemSchema = yup.object().shape({
    score: yup.number().required(),
    count: yup.number().required(),
});

const RatingRecord = immutable.Record({
    id: null,
    course: null,
    comment: null,
    score: null,
    timeUpdated: null,
    userDisplayName: null,
});

export class Rating extends RatingRecord {
    constructor({
        course,
        ...otherAttributes
    }) {
        super({
            course: new RatingCourse(course),
            ...otherAttributes,
        });
    }
}

export const RatingCourseSchema = yup.object().shape({
    id: yup.number().required(),
    title: yup.string().required(),
    group: yup.number().required(),
});

export const RatingSchema = yup.object().shape({
    id: yup.number().required(),
    course: RatingCourseSchema.required(),
    comment: yup.string().required(),
    score: yup.number().required(),
    timeUpdated: yup.date().required(),
    userDisplayName: yup.string().required(),
})
.from('time_updated', 'timeUpdated')
.from('user_display_name', 'userDisplayName');


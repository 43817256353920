import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import LoadingBackdrop from './components/shared/loadingBackdrop';
import { withRouter } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { ENABLE_PRIVACY_SETTING } from './constants';
import { RedirectMessage } from './models/enums';

// We apply code spliting on router to reduce bundle size in general, except for payment-critical screens.
// Code splitting is great but has 1 flaw - 
// if an user has loaded the website with an old version of the bundle right before release, 
// and click onto another page after release,
// the front end might not be able to retrieve other chunks of the old bundle and might blank out.
import CourseEnrollmentScreen from './components/course/courseEnrollmentScreen';
import CourseEnrollmentCompletedScreen from './components/course/courseEnrollmentCompletedScreen';
import AuthenticatedRoute from './components/auth/authenticatedRoute';

const ActivationScreen = lazy(() => import('./components/login/activationScreen'));
const ResetPasswordScreen = lazy(() => import('./components/login/resetPasswordScreen'));
const LoginRegisterScreen = lazy(() => import('./components/login/loginRegisterScreen'));
const RegisterSuccessScreen = lazy(() => import('./components/login/registerSuccessScreen'));
const FirstLoginQuestionnaireScreen = lazy(() => import('./components/login/firstLoginQuestionnaireScreen'));
const BecomeTeacherScreen = lazy(() => import('./components/teacher/becomeTeacherScreen'));
const PageNotExistScreen = lazy(() => import('./components/pageNotFoundScreen'));
const HomeScreen = lazy(() => import('./components/home/homeScreen'));
const CourseGroupDetailsScreen = lazy(() => import('./components/course-group/courseGroupDetailsScreen'));
const CourseGroupRatingScreen = lazy(() => import('./components/course-group/courseGroupRatingScreen'));
const CourseGroupSearchScreen = lazy(() => import('./components/course-group/courseGroupSearchScreen'));
const CourseDetailsScreen = lazy(() => import('./components/course/courseDetailsScreen'));
const CourseLogisticsScreen = lazy(() => import('./components/course/courseLogisticsScreen'));
const CourseManagementScreen = lazy(() => import('./components/course/courseManagementScreen'));
const CourseRatingScreen = lazy(() => import('./components/course/courseRatingScreen'));
const CourseMessageBoardScreen = lazy(() => import('./components/course/courseMessageBoardScreen'));
const CourseRecordingsScreen = lazy(() => import('./components/course/courseRecordingsScreen'));
const ProfileScreen = lazy(() => import('./components/profile/profileScreen'));
const TeacherRatingScreen = lazy(() => import('./components/profile/teacherRatingScreen'));
const AboutScreen = lazy(() => import('./components/aboutScreen'));
const PrivacyPolicyScreen = lazy(() => import('./components/termsAndAgreements/privacyPolicyScreen'));
const ServiceAgreementScreen = lazy(() => import('./components/termsAndAgreements/serviceAgreementScreen'));
const BasicInfoScreen = lazy(() => import('./components/user-settings/screens/basicInfoScreen'));
const CouponScreen = lazy(() => import('./components/user-settings/screens/couponScreen'));
const ChangePasswordScreen = lazy(() => import('./components/user-settings/screens/changePasswordScreen'));
const PrivacySettingScreen = lazy(() => import('./components/user-settings/screens/privacySettingScreen'));
const PaymentMethodsScreen = lazy(() => import('./components/user-settings/screens/paymentMethodsScreen'));
const StudentStatusApplicationScreen = lazy(() => import('./components/user-settings/screens/studentStatusApplicationScreen'));
const MaintenanceScreen = lazy(() => import('./components/maintenance'));
const DiscountTermsScreen = lazy(() => import('./components/termsAndAgreements/discount/discountTermsScreen'));
const BlogListingScreen = lazy(() => import('./components/blog/blogListingScreen'));
const BlogDetailScreen = lazy(() => import('./components/blog/blogDetailScreen'));
const FaqScreen = lazy(() => import('./components/faq/faqScreen'));

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const RoutesProvider = ({ setThemeLight }) => {
    const classes = useStyles();
    setThemeLight(window.location.pathname !== "/become-teacher");
    return (
        <Container className={classes.root}>
                <Suspense fallback={<LoadingBackdrop open={true}/>}>
                <Switch>
                    {process.env.REACT_APP_MAINTENANCE_MODE && <Route component={MaintenanceScreen} />}
                    <Route exact path="/" component={HomeScreen} />
                    <Route exact path="/login" component={LoginRegisterScreen} />
                    <Route path="/activate" component={ActivationScreen} />
                    <Route path="/reset-password" component={ResetPasswordScreen} />
                    <Route exact path="/register-success" component={RegisterSuccessScreen} />
                    <Route exact path="/firstLoginQuestionnaire" component={FirstLoginQuestionnaireScreen} />
                    <Route exact path="/become-teacher" component={BecomeTeacherScreen} />
                    <Route exact path="/about" component={AboutScreen} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
                    <Route exact path="/service-agreement" component={ServiceAgreementScreen} />
                    <Route exact path="/terms/discount" component={DiscountTermsScreen} />
                    <Route exact path="/faq" component={FaqScreen} />
                    <AuthenticatedRoute path="/course/:id/logistics" component={CourseLogisticsScreen} />
                    <AuthenticatedRoute path="/course/:id/management" component={CourseManagementScreen} />
                    <AuthenticatedRoute path="/course/:id/enrolled" component={CourseEnrollmentCompletedScreen} />
                    <AuthenticatedRoute path="/course/:id/enrol" component={CourseEnrollmentScreen} redirectMessage={RedirectMessage.COURSE_ENROLLMENT} />
                    <AuthenticatedRoute path="/course/:id/ratings" component={CourseRatingScreen} />
                    <AuthenticatedRoute path="/course/:id/message-board" component={CourseMessageBoardScreen} />
                    <AuthenticatedRoute path="/course/:id/recordings" component={CourseRecordingsScreen} />
                    <AuthenticatedRoute path="/course/:id" component={CourseDetailsScreen} />
                    <Route path="/course-group/:id/ratings" component={CourseGroupRatingScreen} />
                    <Route path="/course-group/:id" component={CourseGroupDetailsScreen} />
                    <Route exact path="/user/:userId" component={ProfileScreen} />
                    <Route exact path="/user/:userId/ratings" component={TeacherRatingScreen} />
                    <Route path="/search" component={CourseGroupSearchScreen} />
                    <Route path="/blog/:slug" component={BlogDetailScreen} />
                    <Route exact path="/blog" component={BlogListingScreen} />
                    <AuthenticatedRoute exact path="/settings/info" component={BasicInfoScreen} />
                    <AuthenticatedRoute exact path="/settings/coupon" component={CouponScreen} />
                    <AuthenticatedRoute exact path="/settings/password" component={ChangePasswordScreen} />
                    {ENABLE_PRIVACY_SETTING &&
                        <AuthenticatedRoute exact path="/settings/privacy" component={PrivacySettingScreen} />
                    }
                    <AuthenticatedRoute exact path="/settings/paymentMethod" component={PaymentMethodsScreen} />
                    <AuthenticatedRoute exact path="/settings/studentStatusApplication" component={StudentStatusApplicationScreen} />
                    <AuthenticatedRoute exact path="/settings">
                        <Redirect to="/settings/info" />
                    </AuthenticatedRoute>
                    <Route component={PageNotExistScreen} />
                </Switch>
            </Suspense>
        </Container>
    );
};

RoutesProvider.propTypes = {
    setThemeLight: PropTypes.func,
}

export default withRouter(RoutesProvider);

import _ from '../lodash-wrapper';
import * as yup from 'yup';
import * as immutable from 'immutable';

import {PaymentSessionSchema, PaymentSession} from './courseSession';

import {
    RatingItem,
    RatingItemSchema,
} from './rating';

import {
    CourseStatus,
    CourseLanguage,
    CourseMedium,
    Currency,
    CourseCategory,
    CourseLevel,
    SalesMode
} from './enums';

import {
    PublicUserSchema,
    PublicUser
} from './user';

import {
    CourseSessionDetails,
    CourseSessionDetailsSchema
} from './courseSession';

import {
    CourseMaterialDetails,
    CourseMaterialDetailsSchema,
} from './courseMaterial';

import {
    PRICE_AMOUNT_REGEX
} from './utils'

export const courseReducedRecordDefaults = {
    id: null,
    teachers: [],
    otherTeachers: [],
    title: null,
    coverImage: null,
    status: null,
    language: null,
    medium: null,
    priceAmount: null,
    priceCurrency: null,
    isFree: false,
    group: null,
    everlasting: false,
    basePriceAmount: null,
};

const CourseReducedRecord = immutable.Record(courseReducedRecordDefaults);

const GroupDetailsCourseReducedRecord = immutable.Record(immutable.merge(
    courseReducedRecordDefaults,
    {
        startTime: null,
        remainingSeats: null,
        enrollable: false,
        registeredStudentCount: null,
        crowdfundCompleted: false,
        salesMode: null,
        minimumEnrollment: null,
        remainingSeatsToNextCfThreshold: null,
        discountApplied: false,        
    }
));

const PaymentCourseRecord = immutable.Record(immutable.merge(
    courseReducedRecordDefaults,
    {
        sessions: [],
        startTime: null,
        teachingPattern: null,
    }
));

const CourseDetailsRecord = immutable.Record(immutable.merge(
    courseReducedRecordDefaults,
    {
        sessions: [],
        description: null,
        targetAudience: null,
        learningOutcome: null,
        teachingPattern: "",
        ratingDistribution: [],
        ratingAverage: null,
        categories: null,
        courseLevels: null,
        groupRatingDistribution: [],
        groupRatingAverage: null,
        promotionalVideoUrl: "",
        unassignedMaterials: [],
    }
));

const CouponCourseRecord = immutable.Record({
   id: null,
   title: null,
});

export class CourseReduced extends CourseReducedRecord {
    constructor({
        teachers,
        otherTeachers,
        ...otherAttributes
    } = {}) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            ...otherAttributes
        });
    }
}

export class GroupDetailsCourseReduced extends GroupDetailsCourseReducedRecord {
    constructor({
        teachers,
        otherTeachers,
        ...otherAttributes
    } = {}) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            ...otherAttributes
        });
    }
}

export class CourseDetails extends CourseDetailsRecord {
    constructor({
        teachers,
        otherTeachers,
        ratingDistribution,
        groupRatingDistribution,
        sessions,
        unassignedMaterials,
        ...otherAttributes
    } = {}) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            sessions: sessions.map(s => new CourseSessionDetails(s)),
            ratingDistribution: ratingDistribution.map(it => new RatingItem(it)),
            groupRatingDistribution: groupRatingDistribution.map(it => new RatingItem(it)),
            unassignedMaterials: unassignedMaterials.map(m => new CourseMaterialDetails(m)),
            ...otherAttributes
        });
    }
}

export class PaymentCourse extends PaymentCourseRecord{
    constructor({
        teachers,
        otherTeachers,
        sessions,
        ...otherAttributes
    } = {}) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            sessions: sessions.map(s => new PaymentSession(s)),
            ...otherAttributes
        });
        
    }
}

export class CouponCourse extends CouponCourseRecord { }

export const CourseReducedSchema = yup.object().shape({
    id: yup.number().required(),
    teachers: yup.array(PublicUserSchema).required(),
    otherTeachers: yup.array(PublicUserSchema),
    title: yup.string().required(),
    coverImage: yup.string().nullable(),
    status: yup.string().oneOf(_.keys(CourseStatus)),
    language: yup.string().oneOf(_.keys(CourseLanguage)),
    medium: yup.string().oneOf(_.keys(CourseMedium)),
    categories : yup.string().oneOf(_.keys(CourseCategory)),
    priceAmount: yup.string().required().matches(PRICE_AMOUNT_REGEX),
    priceCurrency: yup.string().oneOf(_.keys(Currency)),
    isFree: yup.boolean().required(),
    group: yup.number().required(),
    everlasting: yup.boolean().required(),
    basePriceAmount: yup.number().required(),
})
.from('cover_image', 'coverImage')
.from('course_levels', 'courseLevels')
.from('price_amount', 'priceAmount')
.from('price_currency', 'priceCurrency')
.from('is_free', 'isFree')
.from('other_teachers', 'otherTeachers')
.from('base_price_amount', 'basePriceAmount');

export const GroupDetailsCourseReducedSchema = CourseReducedSchema.shape({
    startTime: yup.date().nullable(),
    remainingSeats: yup.number().nullable(),
    enrollable: yup.boolean().required(),
    registeredStudentCount: yup.number().nullable(),
    crowdfundCompleted: yup.boolean().required(),
    salesMode: yup.string().oneOf(_.keys(SalesMode)).required(),
    minimumEnrollment: yup.number().required(),
    remainingSeatsToNextCfThreshold: yup.number().nullable(),
    discountApplied: yup.boolean().required(),
})
.from('start_time', 'startTime')
.from('remaining_seats', 'remainingSeats')
.from('registered_student_count', 'registeredStudentCount')
.from('crowdfund_completed', 'crowdfundCompleted')
.from('sales_mode','salesMode')
.from('minimum_enrollment','minimumEnrollment')
.from('remaining_seats_to_next_cf_threshold','remainingSeatsToNextCfThreshold')
.from('discount_applied','discountApplied');


export const CourseDetailsSchema = CourseReducedSchema.shape({
    sessions: yup.array(CourseSessionDetailsSchema),
    description: yup.string().required(),
    targetAudience: yup.string().optional(),
    learningOutcome: yup.string().optional(),
    teachingPattern: yup.string().optional(),
    ratingDistribution: yup.array(RatingItemSchema).optional(),
    ratingAverage: yup.number().nullable(),
    categories: yup.array(yup.string().oneOf(_.keys(CourseCategory)).required()).required(),
    courseLevels: yup.array(yup.string().oneOf(_.keys(CourseLevel)).required()).required(),
    groupRatingDistribution: yup.array(RatingItemSchema).optional(),
    groupRatingAverage: yup.number().nullable(),
    promotionalVideoUrl: yup.string().optional(),
    unassignedMaterials: yup.array(CourseMaterialDetailsSchema),
})
.from('target_audience','targetAudience')
.from('learning_outcome', 'learningOutcome')
.from('teaching_pattern', 'teachingPattern')
.from('rating_distribution', 'ratingDistribution')
.from('rating_average', 'ratingAverage')
.from('course_levels', 'courseLevels')
.from('group_rating_distribution', 'groupRatingDistribution')
.from('group_rating_average', 'groupRatingAverage')
.from('promotional_video_url', 'promotionalVideoUrl')
.from('unassigned_materials', 'unassignedMaterials');

export const PaymentCourseSchema = CourseReducedSchema.shape({
    sessions: yup.array(PaymentSessionSchema),
    startTime: yup.date().nullable(),
    teachingPattern: yup.string().optional(),
})
.from('start_time', 'startTime')
.from('teaching_pattern', 'teachingPattern');

export const CouponCourseSchema = yup.object().shape({
    id: yup.string().required(),
    title: yup.string().required(),
});

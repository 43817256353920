import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    CourseMaterialReduced,
    CourseMaterialReducedSchema,
    CourseMaterialDetails,
    CourseMaterialDetailsSchema,
} from './courseMaterial'

const courseSessionReducedDefaults = {
    id: null,
    time: null,
    title: null,
    description: null,
    materials: [],
};

export const courseRecordingSessionDefaults = {
    id: null,
    time: null,
    title: null,
}

const CourseSessionReducedRecord = immutable.Record(courseSessionReducedDefaults);

const CourseSessionDetailsRecord = immutable.Record(immutable.merge(
    courseSessionReducedDefaults,
    {
        durationMinute: null,
    }
));

const PaymentSessionRecord = immutable.Record({
    id: null,
    time: null,
    durationMinute: null,
});

const CourseRecordingSessionRecord = immutable.Record(courseRecordingSessionDefaults);

export class CourseSessionReduced extends CourseSessionReducedRecord {
    constructor({materials, ...otherAttributes} = {})  {
        super({
            materials: materials.map(m => new CourseMaterialReduced(m)),
            ...otherAttributes
        });
    }
}

export class CourseSessionDetails extends CourseSessionDetailsRecord {
    constructor({materials, ...otherAttributes} = {})  {
        super({
            materials: materials.map(m => new CourseMaterialDetails(m)),
            ...otherAttributes
        });
    }
}

export class PaymentSession extends PaymentSessionRecord {
}

export class CourseRecordingSession extends CourseRecordingSessionRecord {}

export const CourseSessionReducedSchema = yup.object().shape({
    id: yup.number().required(),
    time: yup.date().required(),
    title: yup.string().required(),
    description: yup.string().optional(),
    materials: yup.array(CourseMaterialReducedSchema).optional(),
});

export const CourseSessionDetailsSchema = CourseSessionReducedSchema.shape({
    materials: yup.array(CourseMaterialDetailsSchema).optional(),
    durationMinute: yup.number().required(),
})
.from('duration_minute', 'durationMinute');

export const PaymentSessionSchema = yup.object().shape({
    id: yup.number().required(),
    time: yup.date().required(),
    durationMinute: yup.number().required(),
})
.from('duration_minute', 'durationMinute');

export const CourseRecordingSessionSchema = yup.object().shape({
    id: yup.number().required(),
    time: yup.date().required(),
    title: yup.string().required(),
});

import _ from '../lodash-wrapper';
import * as yup from 'yup';
import * as immutable from 'immutable';

import { courseReducedRecordDefaults, CourseReducedSchema } from './course';
import { PublicUser, PublicUserSchema } from './user';
import { CourseRegistrationStatus, SalesMode } from './enums';

const CourseTakenRecord = immutable.Record(immutable.merge(
    courseReducedRecordDefaults,
    {
        enrollable: false,
        remainingSeats: null,
        registrationStatus: null,
        totalDurationMinute: 0,
        startTime: null,
        nextOrLastSessionTime: null,
        registeredStudentCount: null,
        crowdfundCompleted: false,
        salesMode: null,
        minimumEnrollment: null,
        remainingSeatsToNextCfThreshold: null,
        discountApplied: false,   
    }
));

export class CourseTaken extends CourseTakenRecord {
    constructor({teachers, ...otherAttributes}) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            ...otherAttributes
        })
    }
}

export const CourseTakenSchema =  CourseReducedSchema.shape({
    enrollable: yup.boolean().required(),
    remainingSeats: yup.number().nullable(),
    registration_status: yup.string().oneOf(_.keys(CourseRegistrationStatus)),
    totalDurationMinute: yup.number().required(),
    startTime: yup.date().nullable(),
    nextOrLastSessionTime: yup.date().nullable(),
    registeredStudentCount: yup.number().nullable(),
    crowdfundCompleted: yup.boolean().required(),
    salesMode: yup.string().oneOf(_.keys(SalesMode)).required(),
    minimumEnrollment: yup.number().required(),
    remainingSeatsToNextCfThreshold: yup.number().nullable(),
    discountApplied: yup.boolean().required(),
})
.from('remaining_seats', 'remainingSeats')
.from('total_duration_minute', 'totalDurationMinute')
.from('start_time', 'startTime')
.from('next_or_last_session_time', 'nextOrLastSessionTime')
.from('registered_student_count', 'registeredStudentCount')
.from('crowdfund_completed', 'crowdfundCompleted')
.from('sales_mode','salesMode')
.from('minimum_enrollment','minimumEnrollment')
.from('remaining_seats_to_next_cf_threshold','remainingSeatsToNextCfThreshold')
.from('discount_applied','discountApplied');

const CourseTaughtRecord = immutable.Record(immutable.merge(
    courseReducedRecordDefaults,
    {
        enrollable: false,
        remainingSeats: null,
        totalDurationMinute: 0,
        startTime: null,
        nextOrLastSessionTime: null,
        registeredStudentCount: null,
        crowdfundCompleted: false,
        salesMode: null,
        minimumEnrollment: null,
        remainingSeatsToNextCfThreshold: null,
        discountApplied: false,       
    }
));

export class CourseTaught extends CourseTaughtRecord {
    constructor({teachers, ...otherAttributes}) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            ...otherAttributes
        })
    }
}

export const CourseTaughtSchema =  CourseReducedSchema.shape({
    enrollable: yup.boolean(),
    remainingSeats: yup.number().nullable(),
    totalDurationMinute: yup.number().required(),
    startTime: yup.date().nullable(),
    nextOrLastSessionTime: yup.date().nullable(),
    registeredStudentCount: yup.number().nullable(),
    crowdfundCompleted: yup.boolean().required(),
    salesMode: yup.string().oneOf(_.keys(SalesMode)).required(),
    minimumEnrollment: yup.number().required(),
    remainingSeatsToNextCfThreshold: yup.number().nullable(),
    discountApplied: yup.boolean().required(),
})
.from('remaining_seats', 'remainingSeats')
.from('total_duration_minute', 'totalDurationMinute')
.from('start_time', 'startTime')
.from('next_or_last_session_time', 'nextOrLastSessionTime')
.from('registered_student_count', 'registeredStudentCount')
.from('crowdfund_completed', 'crowdfundCompleted')
.from('sales_mode','salesMode')
.from('minimum_enrollment','minimumEnrollment')
.from('remaining_seats_to_next_cf_threshold','remainingSeatsToNextCfThreshold')
.from('discount_applied','discountApplied');

const UserProfileRecord = immutable.Record({
    user: null,
    coursesTaken: [],
    coursesTaught: [],
});

export class UserProfile extends UserProfileRecord {
    constructor({user, coursesTaken, coursesTaught}) {
        super({
            user: new PublicUser(user),
            coursesTaken: coursesTaken.map(c => new CourseTaken(c)),
            coursesTaught: coursesTaught.map(c => new CourseTaught(c)),
        })
    }
}

export const UserProfileSchema = yup.object().shape({
    user: PublicUserSchema.required(),
    coursesTaken: yup.array(CourseTakenSchema).optional(),
    coursesTaught: yup.array(CourseTaughtSchema).optional(),
})
.from('courses_taken', 'coursesTaken')
.from('courses_taught', 'coursesTaught');

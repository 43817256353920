import { bindActionCreators } from 'redux';
import { removeCourseRecordingCookies } from './courseRecordingAction';

function loginSuccess(res) {
    return {
        type: 'LOGIN_SUCCESS',
        ...res
    }
}

function registerSuccess(email) {
    return {
        type: 'REGISTER_SUCCESS',
        email,
    }
}

function logoutSuccess() {
    return {
        type: 'LOGOUT_SUCCESS',
    }
}

function updateCurrentUser(user) {
    return {
        type: 'UPDATE_CURRENT_USER',
        user,
    }
}

export function login(
    email,
    password
) {
    return async (dispatch, _getState, {apiClient}) => {
        const res = await apiClient.login(email, password);
        dispatch(loginSuccess(res));
        return res.user;
    };
}

export function checkRegistrationInput(
    displayName,
    password,
    email,
) {
    return async (_dispatch, _getState, {apiClient}) => {
        return await apiClient.checkRegistrationInput(displayName, password, email);
    };
}

export function register(
    displayName,
    password1,
    password2,
    email,
    first_name,
    last_name,
    date_of_birth,
    recaptcha
) {
    return async (dispatch, _getState, {apiClient}) => {
        await apiClient.register(
            displayName,
            password1,
            password2,
            email,
            first_name,
            last_name,
            date_of_birth,
            recaptcha
        );
        dispatch(registerSuccess(email));
    };
}

export function activateUser(key) {
    return async (_dispatch, _getState, {apiClient}) => {
        return await apiClient.activateUser(key);
    };
}

export function logout() {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        if(token)
            await apiClient.logout(token);
        dispatch(removeCourseRecordingCookies());
        dispatch(logoutSuccess());
    };
}

export function getCurrentUser() {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        dispatch(updateCurrentUser(await apiClient.getCurrentUser(token)));
    };
}

export function hasLoggedIn() {
    return (_dispatch, getState, {_apiClient}) => {
        return getState().auth.token !== null;
    };
}

export function getToken() {
    return (_dispatch, getState, {_apiClient}) => {
        return getState().auth.token;
    };
}

export function resetPassword(recaptcha, email) {
    return async (_dispatch, getState, {apiClient}) => {
        await apiClient.resetPassword(recaptcha, email);
    };
    
}

export function resetPasswordConfirm(uid, key, newPassword) {
    return async (_dispatch, getState, {apiClient}) => {
        await apiClient.resetPasswordConfirm(uid, key, newPassword);
    };
    
}

export function changePassword(oldPassword, newPassword) {
    return async (_dispatch, getState, {apiClient}) => {
        await apiClient.changePassword(getState().auth.token, oldPassword, newPassword);
    };
}

export function validatePassword(password) {
    return async (_dispatch, _getState, {apiClient}) => {
        return await apiClient.validatePassword(password);
    };
}

export function createAuthActionCreator(
    dispatch,
) {
    return bindActionCreators(
        {
            login,
            checkRegistrationInput,
            register,
            activateUser,
            getCurrentUser,
            hasLoggedIn,
            logout,
            resetPassword,
            resetPasswordConfirm,
            changePassword,
            validatePassword
        },
        dispatch,
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { NavLink } from 'react-router-dom';
import { getCourseGroupUrl } from '../../utils';

export default function CourseEnrollmentResult(props) {
    switch(props.result) {
        case 'UNENROLLABLE':
            return (<Alert severity='warning'>十分抱歉！該課程/活動收生人數剛剛超出上限，或課程/活動已經停止收生。我們會檢視報名記錄並盡快退還所付費用（如有）</Alert>);
        case 'COMPLETE':
        case 'PENDING_CONFIRMATION':
        case 'CONFIRMED':
            return (<Alert severity='success'>感謝你的報名。報名過程已經完成，請留意從學識寄來資訊！</Alert>);
        case 'UNKNOWN_PAYMENT_ERROR':
            return (<Alert severity='error'>付款過程出現錯誤，請聯絡我們的團隊已便跟進。</Alert>);
        case 'REQUIRES_PAYMENT_METHOD':
            return(
                <Alert severity='info'>
                    付款手續尚未完成，請返回報名頁面
                    <NavLink to={getCourseGroupUrl(props.course.group)}>繼續報名</NavLink>
                </Alert>
            );
        default:
            return null;
    }
}

CourseEnrollmentResult.propTypes = {
    result: PropTypes.string,
    course: PropTypes.object.isRequired,
}

import * as yup from 'yup';
import * as immutable from 'immutable';

export const cloudFrontCookieRecordDefaults = {
    policy: '',
    signature: '',
    keyPair: '',
    expiryTime: null,
};

const CloudFrontCookieRecord = immutable.Record(cloudFrontCookieRecordDefaults);
export class CloudFrontCookie extends CloudFrontCookieRecord {}

export const CloudFrontCookieSchema = yup.object().shape({
    policy: yup.string().required(),
    signature: yup.string().required(),
    keyPair: yup.string().required(),
    expiryTime: yup.date().required(),
})
.from('key_pair', 'keyPair')
.from('expiry_time', 'expiryTime');

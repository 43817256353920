import * as yup from 'yup';
import * as immutable from 'immutable';

import {CouponCourse, CouponCourseSchema} from "./course";

const couponRecordDefaults = {
    clientFacingCouponCode: null,
    percentOff: null,
    maxRedemption: null,
    timesRedeemed: null,
    expiry: null,
};

const UserCouponRecord = immutable.Record(couponRecordDefaults);

const CourseCouponRecord = immutable.Record(immutable.merge(
    couponRecordDefaults,
    {
        course: null,
    },
));

const AllCouponRecord = immutable.Record({
    courseCoupons: [],
    personalCoupons: [],
    studentCoupon: null,
});

export class UserCoupon extends UserCouponRecord {
}

export class CourseCoupon extends CourseCouponRecord {
    constructor({
        course,
        ...otherAttributes
    } = {}) {
        super({
            course: new CouponCourse(course),
            ...otherAttributes
        });
    }
}

export class AllCoupon extends AllCouponRecord {
    constructor({
        courseCoupons,
        personalCoupons,
        studentCoupon,
        ...otherAttributes
    }) {
        super({
            courseCoupons: courseCoupons.map(c => new CourseCoupon(c)),
            personalCoupons: personalCoupons.map(p => new UserCoupon(p)),
            studentCoupon: studentCoupon && new UserCoupon(studentCoupon),
            ...otherAttributes
        });
    }
}

const CouponBaseSchema = yup.object().shape({
    clientFacingCouponCode: yup.string().required(),
    percentOff: yup.string().required(),
    maxRedemption: yup.number().nullable(true),
    timesRedeemed: yup.number().nullable(true),
    expiry: yup.date(),
});

const UserCouponSchema = CouponBaseSchema
    .from('client_facing_coupon_code', 'clientFacingCouponCode')
    .from('max_redemption', 'maxRedemption')
    .from('percent_off', 'percentOff')
    .from('times_redeemed', 'timesRedeemed');

const CourseCouponSchema = CouponBaseSchema.shape({
    course: CouponCourseSchema,
})
    .from('customer_facing_code', 'clientFacingCouponCode')
    .from('percent_off', 'percentOff')
    .from('quota', 'maxRedemption')
    .from('times_redeemed', 'timeRedeemed')
    .from('expires_at', 'expiry');

export const AllCouponSchema = yup.object().shape({
    courseCoupons: yup.array(CourseCouponSchema),
    personalCoupons: yup.array(UserCouponSchema),
    studentCoupon: UserCouponSchema.nullable(true),
})
    .from('course_coupons', 'courseCoupons')
    .from('personal_coupons', 'personalCoupons')
    .from('student_coupon', 'studentCoupon');

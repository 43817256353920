import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/es/integration/react';

import RoutesProvider from './app/routes';
import configureStore from './app/redux/configureStore';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter, Route} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import './styles/App.css';
import themeLight from "./styles/themeLight";
import themeDark from "./styles/themeDark";
import Header from "./app/components/shared/header";
import Footer from "./app/components/shared/footer";
{/*import adComponent from './app/components/shared/adsense';*/}


const [store, persistor] = configureStore();

const App = () => {
    const [theme, setThemeLight] = React.useState(true);
    return (
        <ThemeProvider theme={theme ? themeLight : themeDark}>{/*Update style for background*/}
            <CssBaseline/>
            <React.StrictMode>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <BrowserRouter>
                            <ThemeProvider theme={theme ? themeLight : themeDark}>{/*Update style for components*/}
                                <Route path="/" component={Header}/> 
                                <div style={{ minHeight: '80vh' }}>
                                    <RoutesProvider setThemeLight={setThemeLight} />
                                </div>
                                {/*<Route component={adComponent}/>*/}
                                <Route path="/" component={Footer}/>
                            </ThemeProvider>
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </React.StrictMode>
        </ThemeProvider>
    );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App/>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

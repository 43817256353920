import * as yup from 'yup';
import * as immutable from 'immutable';

import { PublicUser, PublicUserSchema } from './user';
import {
    RatingItem,
    RatingItemSchema,
    Rating,
    RatingSchema
} from './rating';

const CourseRatingInfoRecord = immutable.Record({
    id: null,
    group: null,
    title: null,
    ratable: false,
    ratingDeadline: null,
    ratingDistribution: [],
    ratingAverage: null,
    teachers: [],
    coverImage: null,
    myRating: null,
    ratings: null,
    groupRatingDistribution: [],
    groupRatingAverage: null
});

export class CourseRatingInfo extends CourseRatingInfoRecord {
    constructor({
        groupRatingDistribution,
        ratingDistribution,
        teachers,
        myRating,
        ratings,
        ...otherAttributes
    }) {
        super({
            groupRatingDistribution: groupRatingDistribution.map(it => new RatingItem(it)),
            ratingDistribution: ratingDistribution.map(it => new RatingItem(it)),
            teachers: teachers.map(t => new PublicUser(t)),
            myRating: myRating && new Rating(myRating),
            ratings: ratings.map(r => new Rating(r)),
            ...otherAttributes
        })
    }
}

export const CourseRatingInfoSchema = yup.object().shape({
    id: yup.number().required(),
    group: yup.number().required(),
    title: yup.string().required(),
    ratable: yup.bool().required(),
    ratingDeadline: yup.date().nullable(),
    ratingDistribution: yup.array(RatingItemSchema).optional(),
    ratingAverage: yup.number().nullable(),
    teachers: yup.array(PublicUserSchema).required(),
    coverImage: yup.string().required(),
    myRating: RatingSchema.nullable(),
    ratings: yup.array(RatingSchema).optional(),
    groupRatingDistribution: yup.array(RatingItemSchema).optional(),
    groupRatingAverage: yup.number().nullable(),
})
.from('rating_distribution', 'ratingDistribution')
.from('rating_average', 'ratingAverage')
.from('cover_image', 'coverImage')
.from('my_rating', 'myRating')
.from('group_rating_distribution', 'groupRatingDistribution')
.from('group_rating_average', 'groupRatingAverage')
.from('rating_deadline', 'ratingDeadline');

import * as yup from 'yup';
import * as immutable from 'immutable';

const StripeUserPortalRecord = immutable.Record({
    portalUrl: '',
});

export class StripeUserPortal extends StripeUserPortalRecord { }

export const StripeUserPortalSchema = yup.object().shape({
    portalUrl: yup.string().required(),
})
    .from('portal_url', 'portalUrl');

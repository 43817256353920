import { CurrentUser } from '../models/user';

const initialState = {
    token: null,
    user: null,
    registerEmail: null,
}

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                user: action.user,
                token: action.key,
            };
        case 'REGISTER_SUCCESS': 
            return {
                ...state,
                registerEmail: action.email,
            }
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                user: null,
                token: null,
            }
        case 'UPDATE_CURRENT_USER':
            return {
                ...state,
                user: action.user,
                token: action.user?state.token:null,
            }
        case 'UPDATED_USER_DETAILS':
            return {
                ...state,
                user: action.user,
            }
        case 'UPDATED_AVATAR':
            return {
                ...state,
                user: new CurrentUser({...state.user, avatar: action.avatar}),
            }
        default:
    }
    return state;
} 
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { persistCombineReducers } from 'redux-persist';
import ReduxLocalStorage from 'redux-persist/lib/storage';
import authReducer from './authReducer';
import redirectReducer from './redirectReducer';
import courseReducer from './courseReducer';

const localStoragePersistConfig = {
    storage: ReduxLocalStorage,
    key: 'root',
    transforms: [
        createWhitelistFilter('auth', ['token']),
        createWhitelistFilter('redirect', ['fromRegister'])
    ]
}

const rootReducer = persistCombineReducers(
    localStoragePersistConfig,
    {
        auth: authReducer,
        redirect: redirectReducer,
        course: courseReducer,
    }
);

export default rootReducer;

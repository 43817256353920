import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';

import { APIClient } from '../apiClient';

export default function configureStore() {
  const middlewares = [
    thunkMiddleware.withExtraArgument({
      apiClient: new APIClient(),
    })
  ];

  const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares)
  );
  const persistor = persistStore(store);

  return [store, persistor];
}
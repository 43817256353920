import * as yup from 'yup';
import * as immutable from 'immutable';

const S3DirectUploadParamsResponseRecord = immutable.Record({
    objectKey: '',
    name: '',
    accesskeyId: '',
    sessionToken: '',
    region: '',
    bucket: '',
    endpoint: '',
    acl: null,
    allowExistenceOptimization: false,
});

export class S3DirectUploadParamsResponse extends S3DirectUploadParamsResponseRecord {}

export const S3DirectUploadParamsResponseSchema = yup.object({
    objectKey: yup.string().required(),
    name: yup.string().required(),
    accesskeyId: yup.string().required(),
    sessionToken: yup.string().nullable(),
    region: yup.string().required(),
    bucket: yup.string().required(),
    endpoint: yup.string().required(),
    acl: yup.string().nullable(),
    allowExistenceOptimization: yup.boolean().required(),
})
.from('object_key', 'objectKey')
.from('access_key_id', 'accesskeyId')
.from('session_token', 'sessionToken')
.from('allow_existence_optimization', 'allowExistenceOptimization');

const S3DirectSignatureResponseRecord = immutable.Record({
    s3ObjKey: ''
});

export class S3DirectSignatureResponse extends S3DirectSignatureResponseRecord {}

export const S3DirectSignatureResponseSchema = yup.object({
    s3ObjKey: yup.string().required(),
});

import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    PublicUser,
    PublicUserSchema,
    CourseMessageUserProfile,
    CourseMessageUserProfileSchema,
} from './user';

const QuoteMessageRecord = immutable.Record({
    id: null,
    content: null,
    timeCreated: null,
    userProfile: null,
});

const CourseMessageRecord = immutable.Record({
    id: null,
    course: null,
    content: null,
    timeCreated: null,
    userProfile: null,
    quote: null,
});

export class QuoteMessage extends QuoteMessageRecord {
    constructor({
        userProfile,
        ...otherAttributes
    }) {
        super({
            userProfile: new CourseMessageUserProfile(userProfile),
            ...otherAttributes
        })
    }
}

export class CourseMessage extends CourseMessageRecord {
    constructor({
        userProfile,
        quote,
        ...otherAttributes
    }) {
        super({
            userProfile: new CourseMessageUserProfile(userProfile),
            quote: quote != null ? new QuoteMessage(quote) : null,
            ...otherAttributes
        })
    }
}

export const QuoteMessageSchema = yup.object().shape({
    id: yup.number().required(),
    content: yup.string().required(),
    timeCreated: yup.date().required(),
    userProfile: CourseMessageUserProfileSchema.required(),
})
    .from('time_created', 'timeCreated')
    .from('user_profile', 'userProfile');

export const CourseMessageSchema = yup.object().shape({
    id: yup.number().required(),
    course: yup.number().required(),
    content: yup.string().required(),
    timeCreated: yup.date().required(),
    userProfile: CourseMessageUserProfileSchema.required(),
    quote: QuoteMessageSchema.nullable(),
})
    .from('time_created', 'timeCreated')
    .from('user_profile', 'userProfile');

const CourseMessageBoardRecord = immutable.Record({
    id: null,
    title: null,
    teachers: [],
    coverImage: null,
    messages: [],
    myRegistration: null,
})

export class CourseMessageBoardInfo extends CourseMessageBoardRecord {
    constructor({
        teachers,
        messages,
        ...otherAttributes
    }) {
        super({
            teachers: teachers.map(t => new PublicUser(t)),
            messages: messages.map(m => new CourseMessage(m)),
            ...otherAttributes
        })
    }
}

export const CourseMessageBoardInfoSchema = yup.object().shape({
    id: yup.number().required(),
    title: yup.string().required(),
    teachers: yup.array(PublicUserSchema).required(),
    coverImage: yup.string().required(),
    messages: yup.array(CourseMessageSchema).optional(),
    myRegistration: yup.boolean().required(),
})
    .from('my_registration', 'myRegistration')
    .from('cover_image', 'coverImage')

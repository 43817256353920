import { setCdnCookies, clearCdnCookies } from '../cookies';

function courseRecordingLoaded(courseId) {
    return {
        type: 'COURSE_RECORDING_LOADED',
        courseId,
    }
}

function courseRecordingCookiesRemoved() {
    return {
        type: 'COURSE_RECORDING_COOKIES_REMOVED',
    }
}

export function retrieveCourseRecordings(
    courseId
) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        const recordingViewResponse = await apiClient.retrieveCourseRecordings(
            token,
            courseId
        );
        setCdnCookies(courseId, recordingViewResponse.cloudFrontCookie);
        dispatch(courseRecordingLoaded(courseId));
        return recordingViewResponse;
    };
}

export function removeCourseRecordingCookies(
) {
    return (dispatch, getState) => {
        const courseIds = getState().course.courseIdsWithCookies;
        clearCdnCookies(courseIds);
        dispatch(courseRecordingCookiesRemoved());
    };
}

export function retrieveAdminCourseRecordings(
    courseId
) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.retrieveAdminCourseRecordings(
            token,
            courseId
        );
    };
}

export function initAdminCourseRecordingUpload(
    courseId,
    sessionId,
    fileName,
    fileType,
    fileSize,
) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.initAdminCourseRecordingUpload(
            token,
            courseId,
            sessionId,
            fileName,
            fileType,
            fileSize,
        );
    };
}

export function signAdminCourseRecordingUploadPart(
    courseId,
    sessionId,
    message,
    signingDate,
    canonicalRequest,
) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.signAdminCourseRecordingUploadPart(
            token,
            courseId,
            sessionId,
            message,
            signingDate,
            canonicalRequest,
        );
    };
}

export function completeAdminCourseRecordingUpload(
    courseId,
    sessionId,
    path,
) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.completeAdminCourseRecordingUpload(
            token,
            courseId,
            sessionId,
            path,
        );
    };
}

export function editCourseRecording(
    courseId,
    recordingId,
    hiddenFromStudents,
) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.editCourseRecording(
            token,
            courseId,
            recordingId,
            hiddenFromStudents
        );
    };
}
import * as yup from 'yup';
import * as immutable from 'immutable';

import { PublicUser, PublicUserSchema } from './user';
import {
    RatingItem,
    RatingItemSchema,
    Rating,
    RatingSchema
} from './rating';
import { PaginatedResultRecord, PaginatedResultSchema } from './pagination';

const CourseGroupRatingInfoRecord = immutable.Record({
    id: null,
    title: null,
    ratingDistribution: [],
    ratingAverage: null,
    ratings: null,
    leadingTeachers: [],
    coverImage: null,
    downsizedCoverImage: null,
});

export class CourseGroupRatingInfo extends CourseGroupRatingInfoRecord {
    constructor({
        ratingDistribution,
        leadingTeachers,
        ratings,
        ...otherAttributes
    }) {
        super({
            ratingDistribution: ratingDistribution.map(it => new RatingItem(it)),
            leadingTeachers: leadingTeachers.map(l => new PublicUser(l)),
            ratings: ratings.map(r => new Rating(r)),
            ...otherAttributes
        })
    }
}

export const CourseGroupRatingInfoSchema = yup.object().shape({
    id: yup.number().required(),
    title: yup.string().required(),
    ratingDistribution: yup.array(RatingItemSchema).optional(),
    ratingAverage: yup.number().nullable(),
    leadingTeachers: yup.array(PublicUserSchema).required(),
    coverImage: yup.string().required(),
    ratings: yup.array(RatingSchema).optional(),
    downsizedCoverImage: yup.string().required(),
})
.from('rating_distribution', 'ratingDistribution')
.from('rating_average', 'ratingAverage')
.from('cover_image', 'coverImage')
.from('leading_teachers', 'leadingTeachers')
.from('downsized_cover_image', 'downsizedCoverImage');

export class CourseGroupRatingList extends PaginatedResultRecord {
    constructor({
        results,
        ...otherAttributes
    }) {
        super({
            results: results.map(r => new Rating(r)),
            ...otherAttributes
        })
    }
}

export const CourseGroupRatingListSchema = PaginatedResultSchema.shape({
    results: yup.array(RatingSchema).optional(),
});

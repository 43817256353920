import * as yup from 'yup';
import * as immutable from 'immutable';

export const PaginatedResultRecord = immutable.Record({
    count: null,
    next: null,
    previous: null,
    results: [],
});

export const PaginatedResultSchema = yup.object().shape({
    count: yup.number().required(),
    next: yup.string().nullable(),
    previous: yup.string().nullable(),
    results: yup.array().optional(),
});
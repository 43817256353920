import React from 'react';
import PropTypes from "prop-types";

import _ from '../../lodash-wrapper';
import { MetaTags as MT } from 'react-meta-tags';

export default function MetaTags(props) {
    const {
        id,
        titles,
        description,
        image,
        profileName,
        prerenderReady,
        statusCode,
    } = props;

    const previewImage = image || `${process.env.PUBLIC_URL}/img/logo192.webp`;

    const titleDisplayed = _.join(
        _.filter(
            [...titles, '學識 Hok6 | 網上教育平台'], 
            v => !_.isNil(v)
        ),
        ' | '
    );

    const descriptionDisplayed = description || '學識 Hok6.com - 「發問、對話、學習，從而認識世界，以廣東話互動的網上教育平台」學識平台包含各類型知識，包括文學、哲學、歷史、科學、資訊科技、心理學、藝術、宗教研究等等。同時亦提供學習廣東話、文言文、日語、法語及中小學教育等各類型網上課程及認証。';
    return (
        <MT id={`meta-tags-${id}`}>
            <title id='title'>{titleDisplayed}</title>
            <meta id='meta-description' name="description" content={descriptionDisplayed} />
            <meta id='meta-description-og' property="og:description" content={descriptionDisplayed} />
            <meta id='meta-title' property="og:title" content={titleDisplayed} />
            <meta id='meta-image' property="og:image" content={previewImage} />
            <meta id='meta-site-name' property="og:site_name" content="學識 Hok6 | 網上教育平台" />
            <meta id='meta-status-code' property="og:site_name" content={statusCode} />
            {profileName && 
                <>
                    <meta id='meta-type-profile' property="og:type" content="profile" />
                    <meta id='meta-username' property="profile:username" content={profileName} />
                </>
            }
            <meta id='meta-prerender-ready' property="prerenderReady" content={prerenderReady?"prerenderReady":"prerenderNotReady"} />
        </MT>
    );
}

MetaTags.propTypes = {
    id: PropTypes.string.isRequired,
    titles: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    image: PropTypes.string,
    profileName: PropTypes.string,
    prerenderReady: PropTypes.bool,
    statusCode: PropTypes.number,
    // statusCode - this is not the status code a human user receives in browser
    // this is referred by the prerendering service and will only be received by crawlers
};

MetaTags.defaultProps = {
    titles: [],
    prerenderReady: true,
    statusCode: 200,
}
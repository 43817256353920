const initialState = {
    fromRegister: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_REDIRECT_FROM_REGISTER':
            return {
                ...state,
                fromRegister: action.path,
            }
        case 'UNSET_REDIRECT_FROM_REGISTER':
            return {
                ...state,
                fromRegister: null,
            }
        default:
    }

    return state;
}
import { bindActionCreators } from 'redux';
import _ from '../lodash-wrapper';
import imageCompression from 'browser-image-compression';
import { IMAGE_COMPRESSION_MAX_SIZE_MB } from '../constants';

export function getUserProfile(userId) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.retrieveUserProfile(token, userId);
    };
}

export function updateUserPreference(publicProfile, publicCoursesTaken) {
    return async(dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.updateUserPreference(token, publicProfile, publicCoursesTaken);
    }
}

export function retrieveUserPreference() {
    return async(dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.retrieveUserPreference(token);
    }
}

function updatedAvatar(avatar) {
    return {
        type: 'UPDATED_AVATAR',
        avatar
    }
}

export function updateAvatar(imageFile) {
    return async(dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        const res = await apiClient.updateAvatar(token, imageFile);
        dispatch(updatedAvatar(res.avatar));
        return res;
    }
}

function updatedUserDetails(user) {
    return {
        type: 'UPDATED_USER_DETAILS',
        user
    }
}

export function updateUserDetails(
    displayName,
    dateOfBirth, // YYYY-MM-DD
    educationDescription,
    firstName,
    gender,
    lastName,
    selfIntroduction,
    interestedCategories,
) {
    return async(dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        const user = await apiClient.updateUserDetails(
            token,
            displayName,
            dateOfBirth,
            educationDescription,
            firstName,
            gender,
            lastName,
            selfIntroduction,
            interestedCategories,
        );
        dispatch(updatedUserDetails(user));
        return user;
    }
}

export function submitFirstLoginQuestionnaire(
    educationDescription,
    interestedCategories,
) {
    return async(dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        const user = await apiClient.submitFirstLoginQuestionnaire(
            token,
            educationDescription,
            interestedCategories,
        );
        dispatch(updatedUserDetails(user));
        return user;
    }
}

async function getCompressedImage(imageUploaded) {
    const imageCompressedBlob = await imageCompression(
        imageUploaded,
        {
            maxSizeMB: IMAGE_COMPRESSION_MAX_SIZE_MB,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: 'image/png',
        }
    );
    imageCompressedBlob.lastModified = new Date();

    return new File(
        [imageCompressedBlob],
        `${_.split(imageUploaded.name, '.', 1)}.png`,
        {
            type: 'image/png',
            lastModified: Date.now(),
        }
    );
}

export function submitStudentStatusApplication(studentCardImageUploaded, selfieImageUploaded) {
    return async (dispatch, getState, { apiClient }) => {
        const token = getState().auth.token;
        const compressedStudentCardImage = await getCompressedImage(studentCardImageUploaded);
        const compressedSelfieImage = await getCompressedImage(selfieImageUploaded);
        return await apiClient.submitStudentStatusApplication(token, compressedStudentCardImage, compressedSelfieImage);
    }
}

export function retrieveStudentStatusApplication() {
    return async (dispatch, getState, { apiClient }) => {
        const token = getState().auth.token;
        return await apiClient.retrieveStudentStatusApplication(token);
    }
}

export function getStripeUserPortalUrl() {
    return async (dispatch, getState, { apiClient }) => {
        const token = getState().auth.token;
        return await apiClient.getStripeUserPortalUrl(token);
    }
}

export function retrieveAllCoupons() {
    return async(dispatch, getState, { apiClient }) => {
        const token = getState().auth.token;
        return await apiClient.retrieveAllCoupons(token);
    }
}

export function listUserRating(userId, page) {
    return async(dispatch, getState, { apiClient }) => {
        return await apiClient.listUserRating(userId, page);
    }
    
}

export function createUserActionCreator(
    dispatch,
) {
    return bindActionCreators(
        {
            getUserProfile,
            retrieveUserPreference,
            updateUserPreference,
            updateAvatar,
            updateUserDetails,
            submitFirstLoginQuestionnaire,
            submitStudentStatusApplication,
            retrieveStudentStatusApplication,
            getStripeUserPortalUrl,
            retrieveAllCoupons,
            listUserRating,
        },
        dispatch,
    );
}

import _ from '../lodash-wrapper';
import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    CourseStatus,
    CourseLanguage,
    CourseMedium,
    CourseCategory,
    CourseLevel,
    CourseGroupType,
    SalesMode
} from './enums';

import {
    PublicUserSchema,
    PublicUser,
} from './user';

import {
    GroupDetailsCourseReduced,
    GroupDetailsCourseReducedSchema,
} from './course';

import {
    CourseSessionReduced,
    CourseSessionReducedSchema,
} from './courseSession';

import {
    CourseMaterialReduced,
    CourseMaterialReducedSchema,
} from './courseMaterial';


import {
    RatingItem,
    RatingItemSchema,
} from './rating';

import { PaginatedResultRecord, PaginatedResultSchema } from './pagination';

import {
    PRICE_AMOUNT_REGEX,
    PRICE_AMOUNT_WITHOUT_DIGITS_REGEX,
} from './utils';

const courseGroupReducedRecordDefaults = {
    id: null,
    type: null,
    title: null,
    coverImage: null,
    leadingTeachers: [],
    otherTeachers: [],
    courseId: null,
    totalDurationMinute: null,
    mediums: [],
    languages: [],
    statuses: [],
    priceCurrency: null,
    priceAmountMin: null,
    priceAmountMax: null,
    enrollable: false,
    firstCourseSalesMode: null,
    firstCourseStatus: null,
    firstCourseDiscountApplied: null,
    firstCourseBasePriceAmount: null,
    firstCoursePriceAmount: null,
    firstCourseMinimumEnrollment: null,
    firstCourseMedium: null,
    firstCourseCrowdfundCompleted: null,
    firstCourseRegisteredStudentCount: null,
    firstCourseRemainingSeatsToNextCfThreshold: null,
    firstCourseRemainingSeats: null,
    firstCourseEnrollable: null,
};

const CourseGroupReducedRecord = immutable.Record(courseGroupReducedRecordDefaults);

const CourseGroupDetailsRecord = immutable.Record(immutable.merge(
    courseGroupReducedRecordDefaults,
    {
        description: null,
        targetAudience: null,
        learningOutcome: null,
        teachingPattern: null,
        faq: '',
        categories: [],
        courseLevels: [],
        sampleSessions: [],
        coursesOngoing: [],
        ratingAverage: null,
        ratingDistribution: [],
        promotionalVideoUrl: "",
        unassignedMaterials: [],
        downsizedCoverImage: "",
        faqSet: [],
    }
));

export class CourseGroupReduced extends CourseGroupReducedRecord {
    constructor({
        leadingTeachers,
        otherTeachers,
        ...otherAttributes
    } = {}) {
        super({
            leadingTeachers: leadingTeachers.map(l => new PublicUser(l)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            ...otherAttributes
        })
    }
}

export class CourseGroupDetails extends CourseGroupDetailsRecord {
    constructor({
        leadingTeachers,
        otherTeachers,
        coursesOngoing,
        sampleSessions,
        ratingDistribution,
        unassignedMaterials,
        ...otherAttributes
    } = {}) {
        super({
            leadingTeachers: leadingTeachers.map(l => new PublicUser(l)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            coursesOngoing: coursesOngoing.map(c => new GroupDetailsCourseReduced(c)),
            sampleSessions:  sampleSessions.map(s => new CourseSessionReduced(s)),
            ratingDistribution: ratingDistribution.map(r => new RatingItem(r)),
            unassignedMaterials: unassignedMaterials.map(m => new CourseMaterialReduced(m)),
            ...otherAttributes
        });
    }
}

export class CourseGroupSearchResult extends PaginatedResultRecord {
    constructor({
        results,
        ...otherAttributes
    }) {
        super({
            results: results.map(r => new CourseGroupReduced(r)),
            ...otherAttributes
        })
    }
}

export const CourseGroupReducedSchema = yup.object().shape({
    id: yup.number().required(),
    type: yup.string().oneOf(_.keys(CourseGroupType)),
    title: yup.string().required(),
    coverImage: yup.string().required(),
    leadingTeachers: yup.array(PublicUserSchema).required(),
    otherTeachers: yup.array(PublicUserSchema),
    courseId: yup.number().nullable(),
    totalDurationMinute: yup.number().required(),
    mediums: yup.array(yup.string().oneOf(_.keys(CourseMedium)).required()).required(),
    languages: yup.array(yup.string().oneOf(_.keys(CourseLanguage)).required()).required(),
    statuses: yup.array(yup.string().oneOf(_.keys(CourseStatus)).required()).required(),
    priceCurrency: yup.string().nullable(),
    priceAmountMin: yup.string().nullable().matches(PRICE_AMOUNT_WITHOUT_DIGITS_REGEX),
    priceAmountMax: yup.string().nullable().matches(PRICE_AMOUNT_WITHOUT_DIGITS_REGEX),
    enrollable: yup.bool().required(),
    firstCourseSalesMode: yup.string().nullable().oneOf(_.keys(SalesMode)),
    firstCourseStatus: yup.string().oneOf(_.keys(CourseStatus)).nullable(),
    firstCourseDiscountApplied: yup.boolean().nullable(),
    firstCourseBasePriceAmount: yup.number().nullable(),
    firstCoursePriceAmount: yup.number().nullable(),
    firstCourseMinimumEnrollment: yup.number().nullable(),
    firstCourseMedium: yup.string().oneOf(_.keys(CourseMedium)).nullable(),
    firstCourseCrowdfundCompleted: yup.boolean().nullable(),
    firstCourseRegisteredStudentCount: yup.number().nullable(),
    firstCourseRemainingSeatsToNextCfThreshold: yup.number().nullable(),
    firstCourseRemainingSeats: yup.number().nullable(),
    firstCourseEnrollable: yup.boolean().nullable(),
})
.from('cover_image', 'coverImage')
.from('leading_teachers', 'leadingTeachers')
.from('other_teachers', 'otherTeachers')
.from('course_id', 'courseId')
.from('total_duration_minute', 'totalDurationMinute')
.from('price_currency', 'priceCurrency')
.from('price_amount_min', 'priceAmountMin')
.from('price_amount_max', 'priceAmountMax')
.from('first_course_status', 'firstCourseStatus')
.from('first_course_sales_mode', 'firstCourseSalesMode')
.from('first_course_discount_applied', 'firstCourseDiscountApplied')
.from('first_course_base_price_amount', 'firstCourseBasePriceAmount')
.from('first_course_price_amount', 'firstCoursePriceAmount')
.from('first_course_minimum_enrollment','firstCourseMinimumEnrollment')
.from('first_course_medium','firstCourseMedium')
.from('first_course_crowdfund_completed', 'firstCourseCrowdfundCompleted')
.from('first_course_registered_student_count','firstCourseRegisteredStudentCount')
.from('first_course_remaining_seats_to_next_cf_threshold','firstCourseRemainingSeatsToNextCfThreshold')
.from('first_course_remaining_seats','firstCourseRemainingSeats')
.from('first_course_enrollable','firstCourseEnrollable');


export const CourseGroupDetailsSchema = CourseGroupReducedSchema.shape({
    description: yup.string().required(),
    target_audience: yup.string().optional(),
    learningOutcome: yup.string().optional(),
    teachingPattern: yup.string().optional(),
    categories: yup.array(yup.string().oneOf(_.keys(CourseCategory)).required()).required(),
    courseLevels: yup.array(yup.string().oneOf(_.keys(CourseLevel)).required()).required(),
    sampleSessions: yup.array(CourseSessionReducedSchema),
    coursesOngoing: yup.array(GroupDetailsCourseReducedSchema),
    ratingAverage: yup.number().nullable(),
    ratingDistribution: yup.array(RatingItemSchema).optional(),
    promotionalVideoUrl: yup.string().optional(),
    faq: yup.string().optional(),
    unassignedMaterials: yup.array(CourseMaterialReducedSchema),
    downsizedCoverImage: yup.string().required(),
    priceAmountMin: yup.string().nullable().matches(PRICE_AMOUNT_REGEX),
    priceAmountMax: yup.string().nullable().matches(PRICE_AMOUNT_REGEX),
    faqSet: yup.array().nullable(),
})
.from('target_audience', 'targetAudience')
.from('learning_outcome', 'learningOutcome')
.from('teaching_pattern', 'teachingPattern')
.from('course_levels', 'courseLevels')
.from('sample_sessions', 'sampleSessions')
.from('courses_ongoing', 'coursesOngoing')
.from('rating_average', 'ratingAverage')
.from('rating_distribution', 'ratingDistribution')
.from('promotional_video_url', 'promotionalVideoUrl')
.from('unassigned_materials', 'unassignedMaterials')
.from('downsized_cover_image', 'downsizedCoverImage')
.from('faq_set','faqSet');

export const CourseGroupSearchResultSchema = PaginatedResultSchema.shape({
    results: yup.array(CourseGroupReducedSchema).optional(),
});

/*
** We are used to import lodash by `import _ from "lodash"` 
** But this would cause the production bundle to include the whole lodash library (~70KB).
**
** Explicit import is favoured to reduce the bundle size.
*/

import concat from "lodash/concat";
import constant from "lodash/constant";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import has from "lodash/has";
import includes from "lodash/includes";
import indexOf from "lodash/indexOf";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isInteger from "lodash/isInteger";
import isNil from "lodash/isNil";
import join from "lodash/join";
import keys from "lodash/keys";
import map from "lodash/map";
import mapKeys from "lodash/mapKeys";
import mapValues from "lodash/mapValues";
import parseInt from "lodash/parseInt";
import range from "lodash/range";
import reduce from "lodash/reduce";
import round from "lodash/round";
import size from "lodash/size";
import slice from "lodash/slice";
import split from "lodash/split";
import values from "lodash/values";
import times from "lodash/times";

export default {
    concat,
    constant,
    debounce,
    filter,
    find,
    get,
    has,
    includes,
    indexOf,
    isArray,
    isEqual,
    isInteger,
    isNil,
    join,
    keys,
    map,
    mapKeys,
    mapValues,
    parseInt,
    range,
    reduce,
    round,
    size,
    slice,
    split,
    values,
    times,
};
import * as yup from 'yup';
import * as immutable from 'immutable';

import {PublicUser, PublicUserSchema} from './user';
import {PaginatedResultRecord, PaginatedResultSchema} from "./pagination";

const BlogReducedRecordDefaults = {
    slug: null,
    author: null,
    coverImage: null,
    title: null,
    publishTime: null,
    preview: null,
};

const BlogListingRecord = immutable.Record(BlogReducedRecordDefaults);

const BlogTagListRecord = immutable.Record({
    tags: [],
});

const BlogDetailRecord = immutable.Record(immutable.merge(
    BlogReducedRecordDefaults,
    {
        content: null,
        bannerImage: null,
        tags: null,
    },
));

export class BlogListing extends BlogListingRecord {
    constructor({
        author,
        ...otherAttributes
    }) {
        super({
            author: new PublicUser(author),
            ...otherAttributes
        });
    }
}

export class BlogDetail extends BlogDetailRecord {
    constructor({
                    author,
                    ...otherAttributes
                }) {
        super({
            author: new PublicUser(author),
            ...otherAttributes
        });
    }
}

export class BlogListingResult extends PaginatedResultRecord {
    constructor({
        results,
        ...otherAttributes
    }) {
        super({
            results: results.map(r => new BlogListing(r)),
            ...otherAttributes,
        });
    }
}

export class BlogTagList extends BlogTagListRecord { }

export const BlogTagListSchema = yup.object().shape({
    tags: yup.array(yup.string()),
});

export const BlogReducedSchema = yup.object().shape({
    slug: yup.string().required(),
    author: PublicUserSchema.required(),
    coverImage: yup.string().required(),
    title: yup.string().required(),
    publishTime: yup.date().required(),
    preview: yup.string().required(),
 })
    .from('cover_image', 'coverImage')
    .from('publish_time', 'publishTime');

export const BlogListingSchema = BlogReducedSchema;

export const BlogDetailSchema = BlogReducedSchema.shape({
    content: yup.string().required(),
    bannerImage: yup.string().required(),
})
    .concat(BlogTagListSchema)
    .from('banner_image', 'bannerImage');

export const BlogListingResultSchema = PaginatedResultSchema.shape({
    results: yup.array(BlogListingSchema).optional(),
});

import * as yup from 'yup';
import * as immutable from 'immutable';

const StudentStatusApplicationRecord = immutable.Record({
    status: null,
    expiry: null,
});

export class StudentStatusApplication extends StudentStatusApplicationRecord { }

export const StudentStatusApplicationSchema = yup.object().shape({
    status: yup.string().required(),
    expiry: yup.date().required(),
});

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, useLocation } from "react-router";
import PropTypes from 'prop-types';

import { hasLoggedIn } from '../../actions/authAction';
import { RedirectMessage } from '../../models/enums';
import PageNotFoundScreen from '../pageNotFoundScreen';

const AuthenticatedRoute = ({
  component: Component,
  redirectMessage,
  ...routeProps
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [noAccess, setNoAccess] = useState(false);

  if (!dispatch(hasLoggedIn())) {
    return <Redirect to={{
      pathname: '/login',
      state: {
        next: location.pathname,
        redirectMessage,
      }
    }} />
  }

  const renderNoAccessScreen = () => {
    return <PageNotFoundScreen />
  };

  const onNoAccess = () => {
    setNoAccess(true);
  };

  return (
    <Route {...routeProps} render={
      (props) => {
        return (
          <>
            {
              !noAccess
                ? <Component onNoAccess={onNoAccess} {...props} />
                : renderNoAccessScreen()
            }
          </>
        )
      }
    } />
  )
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType,
  redirectMessage: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
  redirectMessage: RedirectMessage.DEFAULT,
};

export const AuthenticatedRouteChildPropTypes = {
  onNoAccess: PropTypes.func.isRequired,
};

export default AuthenticatedRoute;
import * as yup from 'yup';
import _ from '../lodash-wrapper';
import * as immutable from 'immutable';

import { PRICE_AMOUNT_REGEX } from './utils';
import { CourseRegistrationStatus, Currency, PaymentSystem } from './enums';
import { UserManagementView, UserManagementViewSchema } from './user';
import { PaymentCourse, PaymentCourseSchema } from './course';


const InitiateCourseRegistrationResponseRecord = immutable.Record({
    clientSecret: null,
    status: null,
    course: null,
    checkoutUrl: null,
    paymentSystem: null,
});

export class InitiateCourseRegistrationResponse extends InitiateCourseRegistrationResponseRecord {
    constructor({
        course,
        ...otherAttributes
    }) {
        super({
            course: new PaymentCourse(course),
            ...otherAttributes
        });
    }
}

export const InitiateCourseRegistrationResponseSchema = yup.object().shape({
    clientSecret: yup.string().nullable(),
    status: yup.string().oneOf(_.keys(CourseRegistrationStatus)).required(),
    course: PaymentCourseSchema.required(),
    checkoutUrl: yup.string().nullable(),
    paymentSystem: yup.string().oneOf([null].concat(_.keys(PaymentSystem))).nullable(),
})
.from('client_secret', 'clientSecret')
.from('checkout_url', 'checkoutUrl')
.from('payment_system', 'paymentSystem');

const CourseRegistrationConfirmResponseRecord = immutable.Record({
    result: null,
    course: null,
});

export class CourseRegistrationConfirmResponse extends CourseRegistrationConfirmResponseRecord {
    constructor({
        course,
        ...otherAttributes
    }) {
        super({
            course: new PaymentCourse(course),
            ...otherAttributes
        });
    }
}

export const CourseRegistrationConfirmResponseSchema = yup.object().shape({
    result: yup.string().oneOf([
        'UNENROLLABLE',
        'COMPLETE',
        'UNKNOWN_PAYMENT_ERROR',
        'REQUIRES_PAYMENT_METHOD'
    ]).required(),
    course: PaymentCourseSchema.required(),
})

const CourseRegistrationManagementViewRecord = immutable.Record({
    id: null,
    course: null,
    student: null,
    status: null,
    timeRegistered: null,
    paymentCurrency: null,
    paymentAmount: null,
});

export class CourseRegistrationManagementView extends CourseRegistrationManagementViewRecord {
    constructor({
        student,
        ...otherAttributes
    }) {
        super({
            student: new UserManagementView(student),
            ...otherAttributes
        });
    }
}

export const CourseRegistrationManagementViewSchema = yup.object().shape({
    id: yup.number().required(),
    course: yup.number().required(),
    student: UserManagementViewSchema.required(),
    status: yup.string().oneOf(_.keys(CourseRegistrationStatus)).required(),
    timeRegistered: yup.date().nullable(),
    paymentCurrency: yup.string().oneOf([null].concat(_.keys(Currency))).nullable(),
    paymentAmount: yup.string().nullable().matches(PRICE_AMOUNT_REGEX),
})
.from('time_registered', 'timeRegistered')
.from('payment_currency', 'paymentCurrency')
.from('payment_amount', 'paymentAmount');
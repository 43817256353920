import * as yup from 'yup';
import * as immutable from 'immutable';

import { RatingItem, RatingItemSchema } from './rating';
import { PublicUser, PublicUserSchema } from './user';

const CourseAnnouncementRecord = immutable.Record({
    id: null,
    message: "",
    sendsNotification: false,
    timeCreated: "",
});

const CourseLogisticsRecord = immutable.Record({
    meetingUrl: "",
    courseMaterialsUrl: "",
    meetingDetails: "",
});

const CourseLogisticsResponseRecord = immutable.Record({
    id: null,
    coverImage: '',
    title: '',
    teachers: [],
    otherTeachers: [],
    logistics: null,
    ratingDistribution: [],
    ratingAverage: null,
    announcements: []
});

export class CourseAnnouncement extends CourseAnnouncementRecord {
}

export class CourseLogistics extends CourseLogisticsRecord {
}

export class CourseLogisticsResponse extends CourseLogisticsResponseRecord {
    constructor({
        logistics,
        announcements,
        ratingDistribution,
        teachers,
        otherTeachers,
        ...otherAttributes
    } = {})  {
        super({
            logistics: new CourseLogistics(logistics),
            announcements: announcements.map(a => new CourseAnnouncement(a)),
            ratingDistribution: ratingDistribution.map(i => new RatingItem(i)),
            teachers: teachers.map(t => new PublicUser(t)),
            otherTeachers: otherTeachers.map(o => new PublicUser(o)),
            ...otherAttributes
        });
    }
}

export const CourseAnnouncementSchema = yup.object().shape({
    id: yup.number().required(),
    message: yup.string().required(),
    sendsNotification: yup.boolean().required(),
    timeCreated: yup.date().optional(),
})
.from('sends_notification', 'sendsNotification')
.from('time_created', 'timeCreated');

export const CourseLogisticsSchema = yup.object().shape({
    meetingUrl: yup.string(),
    meetingDetails: yup.string(),
    courseMaterialsUrl: yup.string(),
})
.from('meeting_url', 'meetingUrl')
.from('meeting_details', 'meetingDetails')
.from('course_materials_url', 'courseMaterialsUrl');

export const CourseLogisticsResponseSchema = yup.object().shape({
    id: yup.number().required(),
    title: yup.string(),
    logistics: CourseLogisticsSchema.required(),
    coverImage: yup.string().required(),
    announcements: yup.array(CourseAnnouncementSchema).optional(),
    ratingDistribution: yup.array(RatingItemSchema).optional(),
    ratingAverage: yup.number().nullable(),
    teachers: yup.array(PublicUserSchema).required(),
    otherTeachers: yup.array(PublicUserSchema),
})
.from('cover_image', 'coverImage')
.from('rating_distribution', 'ratingDistribution')
.from('rating_average', 'ratingAverage')
.from('other_teachers', 'otherTeachers');

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { useContainerDimensions } from '../../utils';
import { BANNER_IMAGE_WIDTH_TO_HEIGHT_RATIO } from '../../constants';

const useStyles = makeStyles(_theme => ({
    bannerImg: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

const BannerImage = ({ imageUrl }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const gridRef = useRef();
    const { width } = useContainerDimensions(gridRef);
    const height = width / BANNER_IMAGE_WIDTH_TO_HEIGHT_RATIO;

    return (
        <Paper
            ref={gridRef}
            square
            className={classes.bannerImg}
            style={{
                backgroundImage: `url(${imageUrl})`,
                paddingTop: `${(height / width) * 100}%`,
                height: height, 
            }}
        />
    );
};
BannerImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
};

export default BannerImage;
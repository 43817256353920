import _ from '../lodash-wrapper';
import * as yup from 'yup';

export class FaqListing {
    constructor(items=[]) {
        this.items = []
        items.forEach(item => this.addItem(item));
    }

    addItem(item) {
        if (this.isValidItem(item)){
            this.items.push(item);
        } else {
            console.error('Invalide Item Type. Expected FaqRecord')
        }
    }

    isValidItem(item) {
        return (
            typeof item === 'object' &&
            'question' in item &&
            'answer' in item &&
            'order' in item
        );
    }
}

const FaqRecordSchema = yup.object().shape({
    question: yup.string().required(),
    answer: yup.string().required(),
    order: yup.number().required(),
 })

export const FaqListSchema = yup.array().of(FaqRecordSchema)
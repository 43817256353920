import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import _ from '../../lodash-wrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import * as portals from 'react-reverse-portal';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import { Link } from 'react-router-dom';
//import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Card } from "@material-ui/core";
import { convertEnumDisplayName } from '../../utils';

//import RatingDistributionCard, { RatingAverageValue } from '../shared/ratingDistributionCard';
import TeacherRatingSummary from '../shared/teacherRatingSummary';
//import { ManagementButton } from './courseTitleNavigation';

import { getProfileUrl, getRatingDetailsUrl } from '../../utils';
import { CourseDetails, PaymentCourse } from '../../models/course';
import { CourseRatingInfo } from '../../models/courseRating';
import { CourseGroupDetails } from '../../models/courseGroup';
import { CourseGroupRatingInfo } from '../../models/courseGroupRating';
import { CourseLogisticsResponse } from '../../models/courseLogistics';
import { CourseMessageBoardInfo } from '../../models/courseMessageBoard';
import { PublicUser } from '../../models/user';
import DetailsSection from '../course/detailsSection';
import { CourseMedium } from '../../models/enums';
import BannerImage from '../shared/bannerImage';
import RatingsPaper from "../profile/ratingsPaper";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    mainContainer: {
        paddingTop: '10px',
        background: '#FFFFFF'
    },
    title: {
        marginTop: 0,
        marginBottom: 10,
        marginRight: 5,
        color: '#333',
        fontWeight: 600,
        letterSpacing: 0.5,
         fontSize: '20px',
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            letterSpacing: 1,
            fontSize: '22px',           
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 20,
            letterSpacing: 2,
            fontSize: '24px',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    ratingSummaryMobile: {
        marginTop: '20px',
        padding: 0,
    },
    ratingsPaper: {
        marginTop: '20px',
        padding: 0,
        overflow: 'auto',
    },

    fullHeight: {
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    leftPaper: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    rightSectionsTitle: {
        color: '#000000',
        ...theme.text.sectionTitle,
        fontWeight: 600,
        marginBottom: '10px',
    },
    mobileNavigationArrow: {
        height: '100%',
    },
    mobileManagementButtonWrapper:{
        marginTop: '8px'
    },
    otherTeachersSectionTitle: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: 500,
    },
    otherTeachersSection: {
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            paddingRight: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
        },
    },
    otherTeachersLink: {
        color: '#6293C3',
        textDecoration: 'none',
    },
    contentContainer: {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '30px',
        paddingBottom: '20px',
    },
    sectionTitle: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: '10px',
    },
    contentText: {
        ...theme.text.content,
        color: '#707070',
        lineHeight: '30px',
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        marginRight: '0px',
        textAlign: 'justify',
    },

}));

const renderOtherTeachersLinks = (classes, otherTeachers) => {
    return otherTeachers.map(teacher => (
        <Link
            key={teacher.id}
            to={getProfileUrl(teacher.id)}
            className={classes.otherTeachersLink}
        >{teacher.displayName}</Link>
    )).reduce((prev, curr, _index) => {
        const glue = _index < otherTeachers.length - 1 ? ", " : " & ";
        return [prev, glue, curr];
    });
};

const renderTextContentSection = (classes, title, content) => {
    return (
        <Container className={classes.contentContainer}>
            <Typography className={classes.sectionTitle} >
                {title}
            </Typography>
            <Typography 
                className={classes.contentText}
                style={{whiteSpace: 'pre-line'}}
            >
                {content}
            </Typography>
        </Container>
    );
}

const renderDesktopMainContainer = ({
    classes,
    portalNode,
    target,
    teachers,
    otherTeachers,
    teacherRatingTitle,
    //targetRatingTitle,
    otherTeachersSectionTitle,
    titleNavigation,
    rightSectionEnabled,
    //targetRatingEnabled,
    //getTargetRatingInfo,
    //knowMoreDestionationButtonText,
    //managementNavigationEnabled,
    isCourseRecordingScreen,
}) => {
    //const { ratingAverage, ratingDistribution, knowMoreDestination } = getTargetRatingInfo(target);
    return (
        <Grid container className={classes.mainContainer} direction='row' alignItems='stretch' spacing={0}>
            <Grid item xs={rightSectionEnabled ? 8 : 12}>
                {(target instanceof CourseGroupDetails || target instanceof CourseDetails) && !isCourseRecordingScreen &&
                    <Grid item style={{margin: '-20px 20px 20px 0px'}}>                                
                        {<BannerImage imageUrl={target.coverImage} />}                               
                    </Grid>
                }
                <Paper className={classNames(classes.fullHeight, classes.leftPaper)} square elevation={0}>
                    <Grid container direction='column' alignItems='stretch'>
                        <Grid container item direction='row' alignItems='center' spacing={2}>
                            <Grid item><Typography className={classes.title}>{target.title}</Typography></Grid>
                            <Grid item>{titleNavigation}</Grid>
                        </Grid>
                        {(target instanceof CourseGroupDetails || target instanceof CourseDetails) && !isCourseRecordingScreen &&
                            <Grid>                    
                                <DetailsSection target={target} />
                                {renderTextContentSection(classes, convertEnumDisplayName(target.medium || target.mediums, CourseMedium), target.teachingPattern)}
                            </Grid>
                        }                
                        <Grid item>
                            <portals.OutPortal node={portalNode} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {rightSectionEnabled &&
                <Grid item xs={4}>
                    <Paper square elevation={0}>
                        <Grid container direction='column' alignItems='stretch' spacing={2} style={{ marginBottom: '0px' }}>
                            <Grid item container direction='column'>
                                <Typography className={classes.rightSectionsTitle}>
                                    {teacherRatingTitle}
                                </Typography>
                                {
                                    teachers.map(teacher => (
                                        <React.Fragment key={teacher.id}>
                                            <TeacherRatingSummary
                                                key={teacher.id}
                                                ratingAverage={teacher.ratingAverage}
                                                ratingDistribution={teacher.ratingDistribution}
                                                displayName={teacher.displayName}
                                                avatarUrl={teacher.avatar}
                                                teacherId={teacher.id}
                                                teacherSelfIntroduction={teacher.selfIntroduction}
                                            />
                                            
                                            <RatingsPaper teacher={teacher} displayMode="simple" className={classes.ratingsPaper}/>
                                        
                                        </React.Fragment>
                                    ))
                                }
                            </Grid>
                        {
                            otherTeachers && otherTeachers.length >= 1 && (
                                <Grid item container direction='column'>
                                    <Typography className={classes.rightSectionsTitle}>
                                        {otherTeachersSectionTitle}
                                    </Typography>
                                    <Grid item className={classes.otherTeachersSection}>
                                        {renderOtherTeachersLinks(classes, otherTeachers)}
                                    </Grid>
                                </Grid>
                            )
                        }
                        {/*anagementNavigationEnabled && <ManagementButton target={target}/>*/}
                            {/*{targetRatingEnabled && <Grid item container direction='column'>
                                <Typography className={classes.rightSectionsTitle}>
                                    {targetRatingTitle}
                                </Typography>
                                <RatingDistributionCard
                                    //ratingAverage={ratingAverage}
                                    //ratingDistribution={ratingDistribution}
                                    knowMoreDestination={knowMoreDestination}
                                    knowMoreDestionationButtonText={knowMoreDestionationButtonText}
                                />
                            </Grid>}*/}
                        </Grid>                        
                    </Paper>
                </Grid>
            } 
        </Grid>
    );
}

const renderMobileMainContainer = ({
    classes,
    target,
    teachers,
    otherTeachers,
    teacherRatingTitle,
    otherTeachersSectionTitle,
    titleNavigation,
    rightSectionEnabled,
    //targetRatingEnabled,
    portalNode,
    //getTargetRatingInfo,
    //managementNavigationEnabled,
    isCourseRecordingScreen,
}) => {
    //const { ratingAverage, knowMoreDestination } = getTargetRatingInfo(target);
    return (
        <Paper className={classNames(classes.leftPaper, classes.fullWidth)} square elevation={0}>
            {(target instanceof CourseGroupDetails || target instanceof CourseDetails) && !isCourseRecordingScreen &&
                <Grid item style = {{margin: '-10px -10px 10px -10px', paddingTop: '0px'}}>                    
                    <BannerImage imageUrl={target.coverImage} />
                </Grid>
            }
            <Grid container className={classes.mainContainer} direction='column' alignItems='stretch' spacing={0}>
                <Grid container direction='row' alignItems='center' spacing={1}>
                    <Grid ><Typography className={classes.title}>{target.title}</Typography></Grid>
                    <Grid >{titleNavigation} </Grid>
                    {/*{rightSectionEnabled && targetRatingEnabled &&
                        <Grid item xs={5} direction='row-reverse' container justifyContent="flex-end">
                            <Button
                                component={Link}
                                to={knowMoreDestination}
                                endIcon={<ArrowForwardIosIcon color='primary' />}
                            >
                                <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', padding: 0 }}>
                                    <RatingAverageValue
                                        ratingAverage={ratingAverage}
                                        naString='暫無評分'
                                    />
                                    <Typography className={classes.targetRatingTitleMobile}>
                                        ({targetRatingTitle})
                                    </Typography>
                                </Container>
                            </Button>
                        </Grid>
                    }*/}
                </Grid>
                {(target instanceof CourseGroupDetails || target instanceof CourseDetails) && !isCourseRecordingScreen &&
                    <Grid>                    
                        <DetailsSection target={target} />
                    </Grid>
                }    
                {rightSectionEnabled && (target instanceof CourseGroupDetails || target instanceof CourseDetails)  &&
                    <Box flexDirection='row' flexWrap='nowrap'>
                        {<Typography className={classes.rightSectionsTitle}>
                            {teacherRatingTitle}
                        </Typography>}
                        <Box>
                        {
                            teachers.map(teacher => (
                                <>
                                <React.Fragment key={teacher.id}>
                                    <TeacherRatingSummary
                                        className={classes.ratingSummaryMobile}
                                        ratingAverage={teacher.ratingAverage}
                                        ratingDistribution={teacher.ratingDistribution}
                                        displayName={teacher.displayName}
                                        avatarUrl={teacher.avatar}
                                        teacherId={teacher.id}
                                        teacherSelfIntroduction={teacher.selfIntroduction}                                        
                                    />
                                    
                                    <RatingsPaper teacher={teacher} displayMode="simple" className={classes.ratingsPaper}/>

                                </React.Fragment>
                                </>
                            ))
                        }

                        </Box>
                    {
                        otherTeachers && otherTeachers.length >= 1 && (
                            <Box className={classes.otherTeachersSection}>
                                <Typography className={classes.otherTeachersSectionTitle}>
                                    {otherTeachersSectionTitle}：
                                    {renderOtherTeachersLinks(classes, otherTeachers)}
                                </Typography>
                            </Box>
                        )
                    }
                        <Box flexGrow={1} className={classes.mobileManagementButtonWrapper}>
                        {/*managementNavigationEnabled && <ManagementButton target={target} />*/}
                        </Box>
                    </Box>
                }
                {(target instanceof CourseGroupDetails || target instanceof CourseDetails) && !isCourseRecordingScreen &&
                    <Grid>                    
                        {renderTextContentSection(classes, convertEnumDisplayName(target.medium || target.mediums, CourseMedium), target.teachingPattern)}
                    </Grid>
                }
                <portals.OutPortal node={portalNode} />
            </Grid>
        </Paper>
    );
}

function CourseAdaptiveContainer(props) {
    const portalNode = React.useMemo(() => portals.createHtmlPortalNode(), []);

    const theme = useTheme();
    const classes = useStyles(theme);
    const desktopVersion = useMediaQuery(theme.breakpoints.up('md'));
    const passThroughProps = { classes, portalNode, ...props };
    return (
        <Card className={classes.root}>
            <portals.InPortal node={portalNode}>
                {props.children}
            </portals.InPortal>
            <Grid container className={classes.mainContainer} direction='row' alignItems='stretch' spacing={0}>
                {desktopVersion ? renderDesktopMainContainer(passThroughProps) : renderMobileMainContainer(passThroughProps)}
            </Grid>
        </Card>
    );
}

const defaultGetRatingInfo = target => {
    return {
        ratingAverage: target.ratingAverage,
        ratingDistribution: target.ratingDistribution,
        knowMoreDestination: getRatingDetailsUrl(target),
    }
}

CourseAdaptiveContainer.propTypes = {
    target: PropTypes.oneOfType([
        PropTypes.instanceOf(CourseDetails).isRequired,
        PropTypes.instanceOf(CourseGroupDetails).isRequired,
        PropTypes.instanceOf(CourseLogisticsResponse).isRequired,
        PropTypes.instanceOf(PaymentCourse).isRequired,
        PropTypes.instanceOf(CourseRatingInfo).isRequired,
        PropTypes.instanceOf(CourseGroupRatingInfo).isRequired,
        PropTypes.instanceOf(CourseMessageBoardInfo).isRequired,
    ]),
    teachers: PropTypes.arrayOf(PropTypes.instanceOf(PublicUser)).isRequired,
    otherTeachers: PropTypes.arrayOf(PropTypes.instanceOf(PublicUser)),
    children: PropTypes.node,
    teacherRatingTitle: PropTypes.string,
    //targetRatingTitle: PropTypes.string,
    otherTeachersSectionTitle: PropTypes.string,
    titleNavigation: PropTypes.node,
    rightSectionEnabled: PropTypes.bool,
    targetRatingEnabled: PropTypes.bool,
    getTargetRatingInfo: PropTypes.func,
    knowMoreDestionationButtonText: PropTypes.string,
    managementNavigationEnabled: PropTypes.bool,
    isCourseRecordingScreen: PropTypes.bool,
}

CourseAdaptiveContainer.defaultProps = {
    //targetRatingTitle: '活動評分',
    teacherRatingTitle: '關於導師',
    otherTeachersSectionTitle: '其他導師',
    titleNavigation: null,
    rightSectionEnabled: true,
    targetRatingEnabled: true,
    getTargetRatingInfo: defaultGetRatingInfo,
    knowMoreDestionationButtonText: '評分',
    managementNavigationEnabled: false,
    isCourseRecordingScreen: false,
}

export default CourseAdaptiveContainer;
import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    courseRecordingBaseDefault,
    CourseRecordingBaseSchema,
} from './courseRecording';

import {
    courseRecordingSessionDefaults,
    CourseRecordingSessionSchema,
} from './courseSession';


const CourseRecordingAdminRecord = immutable.Record(immutable.merge(
    courseRecordingBaseDefault,
    {
        converted: false,
        expiryDate: null,
        session: null,
        hiddenFromStudents: false,
    }
));

export class CourseRecordingAdmin extends CourseRecordingAdminRecord {}

export const CourseRecordingAdminSchema = CourseRecordingBaseSchema.shape({
    converted: yup.boolean().required(),
    expiryDate: yup.date().nullable(),
    session: yup.number().required(),
    hiddenFromStudents: yup.boolean().required(),
})
.from('expiry_date', 'expiryDate')
.from('hidden_from_students', 'hiddenFromStudents');

const CourseRecordingAdminSessionRecord = immutable.Record(immutable.merge(
    courseRecordingSessionDefaults,
    {
        recordings: [],
    }
));

export class CourseRecordingAdminSession extends CourseRecordingAdminSessionRecord {
    constructor({
        recordings,
        ...otherAttributes
    }) {
        super({
            recordings: recordings.map(r => new CourseRecordingAdmin(r)),
            ...otherAttributes
        });
    }
}

export const CourseRecordingAdminSessionSchema = CourseRecordingSessionSchema.shape({
    recordings: yup.array(CourseRecordingAdminSchema).optional(),
});

const CourseRecordingsAdminListResponseRecord = immutable.Record({
    sessions: [],
});

export class CourseRecordingsAdminListResponse extends CourseRecordingsAdminListResponseRecord {
    constructor({
        sessions,
        ...otherAttributes
    }) {
        super({
            sessions: sessions.map(s => new CourseRecordingAdminSession(s)),
            ...otherAttributes
        });
    }
}

export const CourseRecordingsAdminListResponseSchema = yup.object({
    sessions: yup.array(CourseRecordingAdminSessionSchema).optional(),
});

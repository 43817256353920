import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MetaTags from '../shared/meta';
import { Alert } from '@material-ui/lab';
import LoadingBackdrop from '../shared/loadingBackdrop'
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import CourseAdaptiveContainer from './courseAdaptiveContainer';
import CourseEnrollmentResult from './courseEnrollmentResult';
import { confirmCourseRegistration } from '../../actions/courseRegistrationAction';
import { getCourseHomepageUrl } from '../../utils';
import { NoAccessError } from '../../models/error';
import { AuthenticatedRouteChildPropTypes } from '../auth/authenticatedRoute';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        flexGrow: 1,
        marginTop: '20px',
        marginBottom: '20px',
        paddingLeft: 0,
        paddingRight: 0,
    },
    successImageInnerContainer: {
        width: '100%',
        backgroundSize: 'contain',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            height: '300px',
        },
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    buttonGrid: {
        textAlign: 'center',
    },
    navigationSection: {
        marginTop: '30px',
        marginBottom: '30px',
        justifyContent: 'center',
    }
}));

const CourseEnrollmentCompletedScreen = ({ onNoAccess }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();

    const initCourse = location.state && location.state.course;
    const [course, setCourse] = useState(initCourse);
    const courseId = course ? course.id : id;
    const classes = useStyles(useTheme());

    const [error, setError] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState(null);
    useEffect(() => {
        const fetchRegistrationStatus = async () => {
            try {
                const res = await dispatch(confirmCourseRegistration(courseId));
                setRegistrationStatus(res.result);
                setCourse(res.course);

                if (res.result === 'COMPLETE') {
                    // Insert the Google Ads Conversion Event script here
                    window.gtag('event', 'conversion_event_purchase', {
                        // Add any event parameters if needed
                    });
                }
            }
            catch (e) {
                if (e instanceof NoAccessError) {
                    onNoAccess();
                } else {
                    setError(e);
                }
            }
        }
        fetchRegistrationStatus();
        window.scrollTo(0, 0);

    }, [initCourse, dispatch]);

    return (
        <>
            <MetaTags 
                id='course-enrollment-completed'
                titles={['已完成報名', course && course.title]}
                prerenderReady={registrationStatus !== null}
            />
            <LoadingBackdrop open={registrationStatus == null} />
            {course && <CourseAdaptiveContainer
                target={course}
                teachers={course.teachers}
                otherTeachers={course.otherTeachers}
                rightSectionEnabled={false}
                targetRatingEnabled={false}
            >
                <Container className={classes.mainContainer}>
                    {error && <Alert severity='error'>{error.message}</Alert>}
                    {registrationStatus && <CourseEnrollmentResult result={registrationStatus} course={course} />}
                    {registrationStatus === 'COMPLETE' &&
                        <Container className={classes.successImageInnerContainer} style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/img/register_success.webp")` }}>
                            <div />
                        </Container>
                    }
                    <Grid container direction='row' className={classes.navigationSection}>
                        {registrationStatus === 'COMPLETE'
                            && course
                            && <Grid item xs={6} className={classes.buttonGrid}>
                                <Button
                                    component={Link}
                                    variant="contained"
                                    color="primary"
                                    to={getCourseHomepageUrl(course)}
                                >
                                    進入課程/活動
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={6} className={classes.buttonGrid}>
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                to="/"
                            >
                                返回首頁
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </CourseAdaptiveContainer>}
        </>
    );
}

CourseEnrollmentCompletedScreen.propTypes = {
    ...AuthenticatedRouteChildPropTypes,
};

export default CourseEnrollmentCompletedScreen;
import _ from '../lodash-wrapper';
import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    Rating,
    RatingItem,
    RatingItemSchema,
    RatingSchema,
} from './rating';
import { StudentStatus, TeacherStatus, Gender, CourseCategory } from './enums';
import {PaginatedResultRecord, PaginatedResultSchema} from "./pagination";

const CurrentUserRecord = immutable.Record({
    id: null,
    avatar: null,
    dateOfBirth: null,
    educationDescription: null,
    email: null,
    firstName: null,
    gender: null,
    isActive: false,
    lastName: null,
    selfIntroduction: null,
    teacherStatus: null,
    studentStatus: null,
    displayName: null,
    interestedCategories: [],
    firstTimeLogin: false,
    hok6Id: null,
});

export class CurrentUser extends CurrentUserRecord {}

export const CurrentUserSchema = yup.object().shape({
    id: yup.number().required(),
    avatar: yup.string().nullable(),
    dateOfBirth: yup.date().nullable(),
    educationDescription: yup.string().nullable(),
    email: yup.string().required(),
    firstName: yup.string().nullable(),
    gender: yup.string().oneOf(_.keys(Gender)).required(),
    lastName: yup.string().nullable(),
    selfIntroduction: yup.string().nullable(),
    teacherStatus: yup.string().oneOf(_.keys(TeacherStatus)).required(),
    studentStatus: yup.string().oneOf(_.keys(StudentStatus)).required(),
    isActive: yup.boolean().required(),
    displayName: yup.string().required(),
    interestedCategories: yup.array(yup.string().oneOf(_.keys(CourseCategory))).optional(),
    firstTimeLogin: yup.bool().required(),
    hok6Id: yup.number().required(),
})
.from('date_of_birth', 'dateOfBirth')
.from('education_description', 'educationDescription')
.from('first_name', 'firstName')
.from('last_name', 'lastName')
.from('self_introduction', 'selfIntroduction')
.from('teacher_status', 'teacherStatus')
.from('student_status', 'studentStatus')
.from('is_active', 'isActive')
.from('display_name', 'displayName')
.from('interested_categories', 'interestedCategories')
.from('first_time_login', 'firstTimeLogin')
.from('hok6_id', 'hok6Id');

const publicUserRecordDefaults = {
    id: null,
    avatar: null,
    displayName: null,
    isActive: false,
    selfIntroduction: null,
    teacherStatus: null,
    ratingDistribution: [],
    ratingAverage: null,
};

const PublicUserRecord = immutable.Record(publicUserRecordDefaults);

export class PublicUser extends PublicUserRecord {
    constructor({
        ratingDistribution,
        ...otherAttributes
    }) {
        super({
            ratingDistribution: ratingDistribution.map(it => new RatingItem(it)),
            ...otherAttributes
        })
    }
}

export const PublicUserSchema = yup.object().shape({
    id: yup.number().required(),
    avatar: yup.string().nullable(),
    displayName: yup.string().required(),
    isActive: yup.boolean().required(),
    selfIntroduction: yup.string().nullable(),
    teacherStatus: yup.string().oneOf(_.keys(TeacherStatus)).required(),
    ratingAverage: yup.number().nullable(),
    ratingDistribution: yup.array(RatingItemSchema).optional()
})
.from('self_introduction', 'selfIntroduction')
.from('teacher_status', 'teacherStatus')
.from('is_active', 'isActive')
.from('rating_average', 'ratingAverage')
.from('rating_distribution', 'ratingDistribution')
.from('display_name', 'displayName');

const UserManagementViewRecord = immutable.Record(immutable.merge(
    publicUserRecordDefaults,
    {
        firstName: null,
        lastName: null,
        hok6Id: null,
    }
));

export class UserManagementView extends UserManagementViewRecord { }

export const UserManagementViewSchema = PublicUserSchema.shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    hok6Id: yup.number().required(),
})
.from('first_name', 'firstName')
.from('last_name', 'lastName')
.from('hok6_id', 'hok6Id');

const CourseMessageUserProfileRecord = immutable.Record({
    avatar: null,
    displayName: null,
    teacherStatus: null,
});

export const CourseMessageUserProfileSchema = yup.object().shape({
    avatar: yup.string().nullable(),
    displayName: yup.string().required(),
    teacherStatus: yup.string().oneOf(_.keys(TeacherStatus)).required(),
})
    .from('display_name', 'displayName')
    .from('teacher_status', 'teacherStatus');

export class CourseMessageUserProfile extends CourseMessageUserProfileRecord { }

export const LoginResponseSchema = yup.object().shape({
    key: yup.string().required(),
    user: CurrentUserSchema.required(),
});

export const AuthOKResponse = yup.object().shape({
    detail: yup.string().required(),
})

const UserPreferenceRecord = immutable.Record({
    user: null,
    publicProfile: false,
    publicCoursesTaken: false,
});

export class UserPreference extends UserPreferenceRecord {}

export const UserPreferenceSchema = yup.object().shape({
    user: yup.number().required(),
    publicProfile: yup.boolean().required(),
    publicCoursesTaken: yup.boolean().required(),
})
.from('public_profile', 'publicProfile')
.from('public_courses_taken', 'publicCoursesTaken');

const AvatarUpdateResponseRecord = immutable.Record({
    avatar: null,
});

export class AvatarUpdateResponse extends AvatarUpdateResponseRecord {}

export const AvatarUpdateResponseSchema = yup.object().shape({
    avatar: yup.string().nullable(),
});

export class UserRatingList extends PaginatedResultRecord {
    constructor({
        results,
        ...otherAttributes
    }) {
        super({
            results: results.map(r => new Rating(r)),
            ...otherAttributes,
        });
    }
}

export const UserRatingListSchema = PaginatedResultSchema.shape({
    results: yup.array(RatingSchema).optional(),
});

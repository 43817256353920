import * as yup from 'yup';
import * as immutable from 'immutable';

import _ from '../lodash-wrapper';
import {CourseMessageBoardEmailPreference} from "./enums";

const CourseMessageBoardPreferenceRecord = immutable.Record({
    emailNotification: null,
});

export class CourseMessageBoardPreference extends CourseMessageBoardPreferenceRecord { }


export const CourseMessageBoardPreferenceSchema = yup.object().shape({
    emailNotification: yup.string().oneOf(_.keys(CourseMessageBoardEmailPreference)).required(),
})
    .from('email_notification', 'emailNotification');

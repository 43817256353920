import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import MetaTags from './shared/meta'
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Image} from "react-bootstrap";
import pageNotFound from '../../assets/shared/page_not_found.png';
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2em',
    },
  },
  content: {
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      paddingTop: 10
    },
  },
  button: {
    background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    height: 48,
    padding: '0 50px',
    margin: '40px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightImage: {
    maxWidth: '80%',
    textAlign: 'center',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  tabSize:{
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  }
}));

export default function PageNotFound() {
  const classes = useStyles();
  useEffect(() => {
      window.scrollTo(0, 0);       
  },[]);

  return (
      <>
      <MetaTags id='not-found-screen' titles={['頁面不存在']} statusCode={404} />
      <Container>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={6} className={classes.tabSize}>
            <Image className={classes.rightImage} src={pageNotFound}/>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.tabSize}>
            <Typography className={classes.header} variant="h4">喔噢！</Typography>
            <Typography className={classes.content}>您要查找的頁面似乎不存在，或未有公開喔。</Typography>
            <Button className={classes.button} component={NavLink} to="/">返回首頁</Button>
          </Grid>
        </Grid>
      </Container>
      </>
  );
}

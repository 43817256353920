import _ from '../lodash-wrapper';
import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    CourseRegistrationManagementView,
    CourseRegistrationManagementViewSchema
} from './courseRegistration';
import { CourseStatus, Currency } from './enums';

const CourseStatusManagementViewRecord = immutable.Record({
    id: null,
    status: null,
    registrations: [],
    estimatedEarningCurrency: null,
    estimatedEarningAmount: null,
    totalPaymentPercentage: null,

});

export class CourseStatusManagementView extends CourseStatusManagementViewRecord {
    constructor({
        registrations,
        ...otherAttributes
    } = {}) {
        super({
            registrations: registrations.map(r => new CourseRegistrationManagementView(r)),
            ...otherAttributes
        });
    }
}

export const CourseStatusManagementViewSchema = yup.object().shape({
    id: yup.number().required(),
    status: yup.string().oneOf(_.keys(CourseStatus)).required(),
    registrations: yup.array(CourseRegistrationManagementViewSchema).optional(),
    estimatedEarningCurrency: yup.string().oneOf(_.keys(Currency)).required(),
    estimatedEarningAmount: yup.number().required(),
    totalPaymentPercentage: yup.number().required(),
})
.from('estimated_earning_currency', 'estimatedEarningCurrency')
.from('estimated_earning_amount', 'estimatedEarningAmount')
.from('total_payment_percentage', 'totalPaymentPercentage');

import _ from '../lodash-wrapper';
import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    CourseMaterialType
} from './enums'

const courseMaterialsRecordDefaults = {
    id: null,
    title: null,
    type: null,
    public: false,
    file: null,
};

const CourseMaterialReducedRecord = immutable.Record(courseMaterialsRecordDefaults);

const CourseMaterialDetailsRecord = immutable.Record(immutable.merge(
    courseMaterialsRecordDefaults,
    {
        file: null,
    }
))

export class CourseMaterialReduced extends CourseMaterialReducedRecord {
}

export class CourseMaterialDetails extends CourseMaterialDetailsRecord {
}

export const CourseMaterialReducedSchema = yup.object().shape({
    id: yup.number().required(),
    title: yup.string().required(),
    type: yup.string().oneOf(_.keys(CourseMaterialType)).required(),
    public: yup.boolean().required(),
});

export const CourseMaterialDetailsSchema = CourseMaterialReducedSchema.shape({
    file: yup.string().nullable(),
});

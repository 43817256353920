import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _ from '../../lodash-wrapper';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StyledRating } from '../shared/teacherRatingSummary';

import { Rating } from '../../models/rating';
import { getCourseGroupRatingUrl } from "../../utils";

const useStyles = makeStyles(theme => ({
    headingText: {
        ...theme.text.content,
        lineHeight: '30px',
        whiteSpace: 'nowrap',

    },
    margin: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    headingContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0
    },
    desktopRatingCardHeadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0
    },
    ratingStarContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
        }
    },
    ratingCard: {
        marginBottom: '10px',
    },
    groupLinkContainer: {
        marginTop: '10px',
    },
    groupLink: {
        color: '#6293C3',
        fontWeight: 600,
        textDecoration: 'none',
    },
}));

function RatingCard(props) {
    const theme = useTheme();
    const desktopVersion = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles(useTheme());
    const { rating, showGroupLink } = props;

    // Format timeUpdated as day/month/year
    const formattedDate = new Date(rating.timeUpdated).toLocaleDateString("en-GB"); // "en-GB" gives day/month/year format

    return (
        <Card variant='outlined' className={classes.ratingCard}>
            <CardHeader
                title={
                    <Container className={desktopVersion?classes.desktopRatingCardHeadingContainer:classes.headingContainer}>
                        <Typography className={classes.headingText}>{rating.userDisplayName}</Typography>
                        <Container className={classes.ratingStarContainer}>
                            <StyledRating size='small' value={rating.score} readOnly />
                            <Typography className={classNames(classes.margin, classes.headingText)} component='div'>{formattedDate}</Typography>
                        </Container>
                    </Container>
                }
            />
            <CardContent>
                <Grid container justify={'flex-end'}>
                    <Grid item xs={12}>
                        <Typography variant='body2' color='textSecondary' component='p'>
                            {rating.comment}
                        </Typography>
                    </Grid>
                    {showGroupLink && (
                        <Grid item className={classes.groupLinkContainer}>
                            <Typography variant='body2'>
                                {`${rating.course.title} - `}
                                <Link
                                    to={getCourseGroupRatingUrl(rating.course.group)}
                                    className={classes.groupLink}
                                >
                                    課程評分
                                </Link>
                            </Typography>
                        </Grid>
                    )}
                </Grid>

            </CardContent>
        </Card>
    );
}

RatingCard.propTypes = {
    rating: PropTypes.instanceOf(Rating).isRequired,
    showGroupLink: PropTypes.bool.isRequired,
}

export default function RatingList(props) {
    const {
        ratings,
        loadMore,
        hasMore,
        resultExhaustedMessage,
        showGroupLink,
    } = props;
    return (
        <InfiniteScroll
            dataLength={_.size(ratings)}
            next={loadMore}
            loader={<h4>載入中...</h4>}
            hasMore={hasMore}
            endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>{resultExhaustedMessage}</b>
                </p>
              }
            
        >
            {ratings.map(r => (
                 <RatingCard
                    key={r.id}
                    rating={r}
                    showGroupLink={showGroupLink}
                 />
            ))}
        </InfiniteScroll>
    );
}

RatingList.propTypes = {
    ratings: PropTypes.arrayOf(PropTypes.instanceOf(Rating)),
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    resultExhaustedMessage: PropTypes.string,
    showGroupLink: PropTypes.bool,
}

RatingList.defaultProps = {
    resultExhaustedMessage: '', //'以上為已公開的所有評論',
    showGroupLink: false,
}
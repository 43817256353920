import { bindActionCreators } from 'redux';

export function initiateCourseRegistration(courseId) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.initiateCourseRegistration(token, courseId);
    };
}

export function confirmCourseRegistration(courseId) {
    return async (dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.confirmCourseRegistration(token, courseId);
    };
}

export function createCourseRegistrationActionCreator(
    dispatch,
) {
    return bindActionCreators(
        {
            initiateCourseRegistration,
            confirmCourseRegistration
        },
        dispatch,
    );
}

import _ from '../lodash-wrapper';
import { updateLogisticsValidationErrorTranslate } from '../utils';
import { UpdateLogisticsViewValidationText } from './enums';

export class Hok6Error extends Error {
    constructor(message = '未知錯誤', srcError = null) {
        super(message);
        this.srcError = srcError;
    }
    getErrorFieldProps = () => {
        return {}
    }
}

export class NoAccessError extends Hok6Error { }

export class UserUnverifiedError extends Hok6Error {}

export class InvalidCredentialsError extends Hok6Error {}

export class CoursePublishError extends Hok6Error {}

export class InvalidFieldsError extends Hok6Error {
    constructor(errMsg, srcError) {
        super(errMsg, srcError);
        this.errorFields = {}
        _.mapKeys(srcError.response.data, (messages, fieldName) => {
            this.errorFields[fieldName] = messages[0];
        })
    }
    getErrorFieldProps = fieldId => {
        const message = _.get(this.errorFields, fieldId);
        if(!message) 
            return {}
        return {
            error: true,
            helperText: message
        }
    }
}

export class PaymentNotNeededError extends Hok6Error {}
export class CourseUnenrollableError extends Hok6Error {}
export class CourseUnratableError extends Hok6Error {}
export class NetworkError extends Hok6Error {}
export class UnauthorizedError extends NoAccessError { }
export class ForbiddenError extends NoAccessError {}
export class NotFoundError extends NoAccessError {}

export class UpdateCourseLogisticsValidationError extends InvalidFieldsError {
    constructor(srcError) {
        super('輸入無效', srcError);
        this.errorFields = {}
        _.mapKeys(srcError.response.data, (messages, fieldName) => {
            const errorValue = updateLogisticsValidationErrorTranslate(messages[0]);
            this.errorFields[fieldName] = _.get(UpdateLogisticsViewValidationText, [errorValue, 'displayName'], errorValue);
        });
    }
}

export const parseAuthError = e => {
    if(e.response && e.response.data.non_field_errors) {
        const messages = _.values(e.response.data.non_field_errors);
        if(_.indexOf(messages, '請先前往電郵信箱查閱驗證電郵。') !== -1)
            return new UserUnverifiedError('電郵地址不正確。');
        if(_.indexOf(messages, '無法以此用戶登入╒') !== -1)
            return new InvalidCredentialsError('登入電郵或密碼錯誤。');
    }
    return parseGenericError(e);
}

export const parseCourseRegistrationError = e => {
    if(e.response && e.response.status === 404)
        return new NotFoundError('Course not found');

    if(e.response && e.response.data.detail) {
        const message = e.response.data.detail;
        switch(message) {
            case 'Registration does not need payment now':
                return new PaymentNotNeededError('Course registration does not require payment for now');
            case 'Course is not enrollable by teaching staff':
                return new CourseUnenrollableError('閣下是此課程的導師, 請由「我的學識 」進入此課程的課堂頁面');
            case 'Course is not enrollable':
                return new CourseUnenrollableError('由於人數已滿或報讀期限屆滿，暫時無法報讀課程');
            default:
                break;
        }
    }
    return parseGenericError(e);
}

export const parseCoursePublishError = e => {
    if(e.response.status === 406 && e.response.data.reason) {
        return new CoursePublishError(e.response.data.reason);
    }
    return parseGenericError(e);
}

export const parseCourseRatingError = e => {
    if(e.response.status === 406) {
        return new CourseUnratableError('現時無法新增/更改評分');
    }
    return parseGenericError(e);

}

export const parseUpdateCourseLogisticsError = e => {
    if (e.response && e.response.status === 400) {
        return new UpdateCourseLogisticsValidationError(e);
    }
    return parseGenericError(e);
}

export const parseSubmitStudentStatusApplicationError = e => {
    if (e.response && e.response.status === 400 && e.response.data[0]) {
        const message = e.response.data[0];
        switch (message) {
            case 'Student card image is missing':
                return new Hok6Error('請上傳學生身份證明文件');
            case 'Selfie image is missing':
                return new Hok6Error('請上傳自拍照');
            case 'File size of student card image exceeds limit':
                return new Hok6Error('上傳的學生身份證明文件超出伺服器允許的文件大小限制，請嘗試壓縮圖片後重新上傳');
            case 'File size of selfie image exceeds limit':
                return new Hok6Error('上傳的自拍照超出伺服器允許的文件大小限制，請嘗試壓縮圖片後重新上傳');
            case 'Only one in process application is allowed':
                return new Hok6Error('系統已經正在審核你的申請，請勿重複提交申請');
            default:
                break;
        }
    }
    return parseGenericError(e);
}

export const parseGenericError = e => {
    if(e.response) {
        if(e.response.status === 401)
            return new UnauthorizedError('需要登入');
        if(e.response.status === 404)
            return new NotFoundError('頁面不存在');
        if(e.response.status === 403)
            return new ForbiddenError('拒絕存取');
        if(e.response.status === 400)
            return new InvalidFieldsError('輸入無效', e);
    }
    return new Hok6Error(e.message, e);
}

import { useState, useEffect } from 'react';
import _ from './lodash-wrapper';
import { format, utcToZonedTime } from 'date-fns-tz'
import zhTW from "date-fns/locale/zh-TW";

import {CourseDetails} from './models/course';
import {CourseGroupDetails} from './models/courseGroup';
import {CourseLogisticsResponse} from './models/courseLogistics';

import { DATE_DISPLAY_FORMAT, RATING_MAX } from './constants';

export const getHost = () => process.env.REACT_APP_FRONTEND_DOMAIN;
export const getBackendHost = () => process.env.REACT_APP_BACKEND_DOMAIN;
export const getBackendBaseUrl = () => `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_DOMAIN}`;

export const getCourseHomepageUrl = course => course.everlasting? getCourseRecordingsUrl(course.id): getCourseLogisticsUrl(course.id);
export const getCourseDetailsUrl = courseId => `/course/${courseId}`;
export const getCourseEnrolledUrl = id => `/course/${id}/enrolled`;
export const getCourseEnrollmentUrl = id => `/course/${id}/enrol`;
export const getCourseLogisticsUrl = courseId => `/course/${courseId}/logistics`;
export const getCourseManagementUrl = id => `/course/${id}/management`;
export const getCourseRatingUrl = id => `/course/${id}/ratings`;
export const getCourseMessageBoardUrl = id => `/course/${id}/message-board`;
export const getCourseRecordingsUrl = id => `/course/${id}/recordings`;
export const getCourseGroupUrl = id => `/course-group/${id}`;
export const getCourseGroupRatingUrl = id => `/course-group/${id}/ratings`;
export const getCourseGroupSearchUrl = queryString => '/search'+(_.size(queryString)?`?${queryString}`:'');
export const getProfileUrl = id => `/user/${id}`;
export const getTeacherRatingUrl = id => `/user/${id}/ratings`;
export const getBlogDetailUrl = slug => `/blog/${slug}`;

export const getRatingDetailsUrl = target => {
    if(target instanceof CourseGroupDetails)
        return getCourseGroupRatingUrl(target.id);
    if(target instanceof CourseDetails || target instanceof CourseLogisticsResponse)
        return getCourseRatingUrl(target.id);
    return '#';
}

export const numberMap = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    10: '十',
};

export const discountNumberMap = (discountPercentage, numberMap) => {
    const discountPercentageStr = discountPercentage.toString()
    if( discountPercentageStr[1] === '0' ) {
        return `${numberMap[discountPercentageStr[0]]}折`
    }
    return `${numberMap[discountPercentageStr[0]]}${numberMap[discountPercentageStr[1]]}折`
};

export const getScoreDisplayText = score => {
    return `${numberMap[score]}星`;
}

export const convertRatingDistributionToPercentage = distribution => {
    let scoreArr = _.times(RATING_MAX+1, _.constant(0));
    let total = 0;
    distribution.forEach(it => {
        scoreArr[it.score] = it.count;
        total += it.count;
    });
    scoreArr = scoreArr.map(count => count? count/total*100: 0);
    return {scoreArr, total};
}

export const convertEnumDisplayName = (ids, enumType, glue='/') => {
    if(_.isArray(ids)) {
        return ids.map(id => enumType[id].displayName).join(glue);
    }
    return enumType[ids].displayName;
}

export const getPriceText = (currency, amount) => `${currency} ${amount}`;

// https://stackoverflow.com/a/60978633
export const useContainerDimensions = ref => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        const getDimensions = () => ({
            width: ref.current.offsetWidth,
            height: ref.current.offsetHeight
        });
        const handleResize = () => {
            setDimensions(getDimensions());
        };
        if (ref.current) {
            setDimensions(getDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [ref]);
  
    return dimensions;
  };


export const passwordValidationErrorTranslate = (errorMessage) => {
    switch (errorMessage) {
        case 'Invalid password':
            return 'INVALID_PASSWORD';
        case 'This password is too short. It must contain at least 8 characters.':
            return 'SHORT_PASSWORD';
        case 'This password is too common.':
            return 'COMMON_PASSWORD';
        case 'This password is entirely numeric.':
            return 'NUMERIC_PASSWORD';
        case 'This field may not be blank.':
            return 'NEW_PASSWORD_EMPTY';
        case 'The password is too similar to the email.':
            return 'EMAIL_SIMILAR_PASSWORD';
        case undefined:
            return null;
        default:
            return errorMessage;
    }
}

export const accountValidationErrorTranslate = (errorMessage) => {
    switch (errorMessage) {
        case 'Enter a valid email address.':
            return 'INVALID_EMAIL';
        case 'A user is already registered with this e-mail address.':
            return 'REGISTERED_EMAIL';
        case undefined:
            return null;
        default:
            return errorMessage;
    }
}

export const updateLogisticsValidationErrorTranslate = (errorMessage) => {
    switch (errorMessage) {
        case 'Enter a valid URL.':
            return 'INVALID_URL';
        case 'Ensure this field has no more than 1000 characters.':
            return 'INVALID_LENGTH_URL';
        case undefined:
            return null;
        default:
            return errorMessage;
    }
}

export const datetimeFormat = (datetime, datetimeFormat) => {
    if (!datetime) {
        return '-';
    }
    else {
        const fixedDatetime = utcToZonedTime(datetime, 'Asia/Hong_Kong');
        return format(fixedDatetime, datetimeFormat, {locale: zhTW})
    }
}

export const formatDateOfBirth = (datetime) => {
    if (!datetime) {
        return '-';
    } else {
        // Create a new date object and extract only the date part in YYYY-MM-DD format
        const year = datetime.getFullYear();
        const month = String(datetime.getMonth() + 1).padStart(2, '0'); // Add leading zero
        const day = String(datetime.getDate()).padStart(2, '0'); // Add leading zero
        return `${year}-${month}-${day}`; // Formats as YYYY-MM-DD
    }
};

export const mergeArrayUnique = (arr1, arr2, keyFunc=a => String(a)) => {
    let result = [], keys = {};
    [...arr1, ...arr2].forEach(
        element => {
            const key = keyFunc(element);
            if(!keys[key]) {
                result.push(element);
            }
            keys[key] = true;
        },
    );
    return result;
}

export const isToday = (time) => {
    if (!(time instanceof Date)) {
        return false;
    }

    return format(new Date(), DATE_DISPLAY_FORMAT) === format(time, DATE_DISPLAY_FORMAT);
};

export const isGteToday = (time, now = new Date()) => {
    if (!(time instanceof Date)) {
        return false;
    }

    return now >= time;
};

import * as yup from 'yup';
import * as immutable from 'immutable';

import {
    CourseDetails,
    CourseDetailsSchema,
} from './course';

import {
    CloudFrontCookie,
    CloudFrontCookieSchema,
} from './cloudFront';

import {
    CourseRecordingSession,
    CourseRecordingSessionSchema,
} from './courseSession';

export const courseRecordingBaseDefault = {
    id: null,
    description: '',
    title: '',
};

export const CourseRecordingBaseSchema = yup.object().shape({
    id: yup.number().required(),
    title: yup.string().required(),
    description: yup.string(),
});

const CourseRecordingRecord = immutable.Record(immutable.merge(
    courseRecordingBaseDefault,
    {
        convertedFile: '',
        session: null,
    }
));

export class CourseRecording extends CourseRecordingRecord {
    constructor({
        session,
        ...otherAttributes
    }) {
        super({
            session: new CourseRecordingSession(session),
            ...otherAttributes,
        })
    }
}
export const CourseRecordingRecordSchema = CourseRecordingBaseSchema.shape({
    convertedFile: yup.string().required(),
    session: CourseRecordingSessionSchema.required(),
})
.from('converted_file', 'convertedFile');

export const courseRecordingResponseRecordDefaults = {
    recordings: [],
    course: null,
    cloudFrontCookie: null,
};
const CourseRecordingResponseRecord = immutable.Record(courseRecordingResponseRecordDefaults);
export class CourseRecordingResponse extends CourseRecordingResponseRecord {
    constructor({
        recordings,
        course,
        cloudFrontCookie,
        ...otherAttributes
    } = {}) {
        super({
            recordings: recordings.map(r => new CourseRecording(r)),
            course: new CourseDetails(course),
            cloudFrontCookie: new CloudFrontCookie(cloudFrontCookie),
            ...otherAttributes
        });
    }
}
export const CourseRecordingResponseSchema = yup.object().shape({
    recordings: yup.array(CourseRecordingRecordSchema).optional(),
    cloudFrontCookie: CloudFrontCookieSchema.required(),
    course: CourseDetailsSchema.required(),
})
.from('cloudfront_cookie', 'cloudFrontCookie');

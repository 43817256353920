import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {Image} from "react-bootstrap";
import {NavLink, withRouter} from "react-router-dom";
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
    Toolbar
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowRightIcon from '@material-ui/icons/Face';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from "clsx";

import {createAuthActionCreator} from '../../actions/authAction';
import Bg_1 from "../../../assets/shared/bg_1.png";
import Logo from "../../../assets/shared/logo.png";
import Link from "@material-ui/core/Link";

const mobileMenuWidth = 200;
const style = theme => ({
    grow: {
        flexGrow: 1,
    },
    /*boxContainer: {
        width: '100vw',
        overflowX: 'hidden',
    },*/
    toolBar: {
        paddingTop: 5,
        paddingBottom: 5,
        backgroundSize: `200px`,
        background: `url(${Bg_1}) no-repeat scroll 6px -90px`,        
        [theme.breakpoints.up('sm')]: {
            paddingTop: 10,
            paddingBottom: 10,
            backgroundSize: `300px`,
            background: `url(${Bg_1}) no-repeat scroll -15px -180px`,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 15,
            paddingBottom: 15,
            background: `url(${Bg_1}) no-repeat scroll -25px -215px`,
            backgroundSize: `380px`,
        },
    },
    sectionMobile: {
        display: 'flex',
        fontSize: '1.3em',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-cell',
            position: 'relative',
            padding: '15px 0',
        },
    },
    logo: {
        marginTop: '-10px',
        marginLeft: '-5px',
        width: '95px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '-15px',
            width: '105px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '-5px',
            width: '140px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-10px',
        },
        
    },
    logoButton: {
        fontWeight: '800',
        display: 'inline-block',
        padding: '15px 20px',
        position: 'relative',
    },
    button: {
        fontWeight: '600',
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '0.15em',
        display: 'inline-block',
        padding: '15px 12px',
        position: 'relative',
        fontSize: '1.3em',
        "&:before": {
            content: '""',
            position: 'absolute',
            width: '80%',
            height: '3px',
            bottom: 0,
            left: '10%',
            backgroundColor: theme.palette.primary.main,
            visibility: 'hidden',
            transform: 'scaleX(0)',
            transition: 'all .5s cubic-bezier(1,.25,0,.75) 0s'
        },
        "&:hover": {
            textDecoration: 'none',
            "&:before": {
                visibility: 'visible',
                transform: 'scaleX(1)'
            },
        },
        [theme.breakpoints.up('lg')]: {
            padding: '15px 20px',
        },
    },
    active: {
        "&:before": {
            visibility: 'visible',
            transform: 'scaleX(1)'
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawer: {
        width: mobileMenuWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: mobileMenuWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -mobileMenuWidth,
    },
    fullList: {
        width: 'auto',
    },
});
const useStyles = makeStyles(style);

const MenuLink = props => {
    const classes = useStyles();
    const {startIcon: _startIcon, ...otherProps} = props;
    return (
    <Link color="primary"
            className={`${classes.button} ${window.location.pathname === props.to ? classes.active : ''}`}
            size="large" component={NavLink} {...otherProps}>
        {props.text}
    </Link>
    );
};

MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    startIcon: PropTypes.node,
};

const MenuLinkMobile = props => {
    const {startIcon, text, to, ...otherProps} = props;
    const isActive = window.location.pathname === to; // Check if the menu item is active

    return (
        <ListItem button component={NavLink} {...otherProps} to={to}>
            <ListItemIcon>{isActive ? <ArrowRightIcon /> : startIcon}</ListItemIcon> {/* Show dot icon if active */}
        <ListItemText primary={text}/>
    </ListItem>
    );
};

MenuLinkMobile.propTypes = {
    ...MenuLink.propTypes,
};

class Header extends React.PureComponent {
    state = {
        menuOpen: false,
    }

    componentDidMount = async () => {
        if (this.props.token && !this.props.currentUser) {
            await this.props.authAction.getCurrentUser();
        }
    }

    logout = async () => {
        await this.props.authAction.logout();
        this.props.history.push('/login');
    }

    render() {
        const props = this.props;
        const classes = this.props.classes;
        const state = this.state;

        const mobileMenuOpen = (open) => (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            this.setState({menuOpen: open});
        };

        return (
            <Box className={classes.boxContainer}>
                <Toolbar className={classes.toolBar}>
                    <MenuLink to='/' className={classes.logoButton}
                              text={<Image src={Logo} className={classes.logo}/>}/>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <MenuLink to="/search" text="搜尋學識"/>
                        <MenuLink to="/blog" text="閱讀學識"/>
                        <MenuLink to="/become-teacher" text="傳授學識"/>
                        <MenuLink to="/about" text="關於學識"/>
                        {props.currentUser
                            ? <>
                                <MenuLink to={`/user/${props.currentUser.id}`} text="我的學識"/>
                                <MenuLink to='/settings' text="設定"/>
                                <MenuLink to='#' onClick={this.logout} text="登出"/>
                            </>
                            : <MenuLink to='/login'
                                        className={`${classes.button} ${(window.location.pathname === "/login" ||
                                            window.location.pathname === "/activate") ? classes.active : ''}`}
                                        text="登入/註冊"/>
                        }
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton color="primary" aria-label="show more" onClick={mobileMenuOpen(true)}>
                            <MenuIcon style={{ fontSize: '1.8em' }} /> {/* Set the fontSize to enlarge the button */}
                        </IconButton>
                    </div>
                </Toolbar>
                <SwipeableDrawer
                    anchor="right"
                    open={state.menuOpen}
                    onClose={mobileMenuOpen(false)}
                    onOpen={mobileMenuOpen(true)}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div
                        className={clsx(classes.list, {[classes.fullList]: "right" === 'top' || "right" === 'bottom',})}
                        role="presentation"
                        onClick={mobileMenuOpen(false)}
                        onKeyDown={mobileMenuOpen(false)}>
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={mobileMenuOpen(false)} style={{color: '#6293C3'}}>
                                {props.theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                            </IconButton>
                        </div>
                        <Divider/>
                        <List style={{color: '#6293C3'}}>
                            <MenuLinkMobile to='/search' text="搜尋學識" />
                            <MenuLinkMobile to='/blog' text="閱讀學識"/>
                            <MenuLinkMobile to='/become-teacher' text="傳授學識"/>
                            <MenuLinkMobile to='/about' text="關於學識"/>
                            {props.currentUser
                                ? <>
                                    <MenuLinkMobile to={`/user/${props.currentUser.id}`} text="我的學識" />
                                    <MenuLinkMobile to='/settings' text="設定"/>
                                    <MenuLinkMobile to='#' text="登出" onClick={this.logout}/>
                                </>
                                : <MenuLinkMobile to='/login' text="登入/註冊"/>
                            }
                        </List>
                    </div>
                </SwipeableDrawer>
            </Box>
        );
    }
}

Header.propTypes = {
    token: PropTypes.string,
    currentUser: PropTypes.object,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    authAction: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        authAction: createAuthActionCreator(dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(style, {withTheme: true})(Header)));

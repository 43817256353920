const initialState = {
    courseIdsWithCookies: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'COURSE_RECORDING_LOADED':
            return {
                ...state,
                courseIdsWithCookies: [...state.courseIdsWithCookies, action.courseId],
            }
        case 'COURSE_RECORDING_COOKIES_REMOVED':
            return {
                ...state,
                courseIdsWithCookies: [],
            }
        default:
    }
    return state;
}
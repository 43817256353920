import Cookies from 'js-cookie'

const DEFAULT_FLAGS = {
    secure: true,
}
const ASSETS_CDN_DEFAULT_FLAGS = {
    ...DEFAULT_FLAGS,
    domain: `.${process.env.REACT_APP_SHARED_TOPMOST_DOMAIN}`,
}

const getCDNCookiesFlag = courseId => {
    // No expiry, keep it as session cookie
    return {
        ...ASSETS_CDN_DEFAULT_FLAGS,
        path: `/course-videos-outbox/${courseId}/`,
    }
}

export const setCdnCookies = (courseId, cdbCookiesInfo) => {
    const cdbFlags = getCDNCookiesFlag(courseId);
    Cookies.set('CloudFront-Policy', cdbCookiesInfo.policy, cdbFlags);
    Cookies.set('CloudFront-Signature', cdbCookiesInfo.signature, cdbFlags);
    Cookies.set('CloudFront-Key-Pair-Id', cdbCookiesInfo.keyPair, cdbFlags);
}

export const clearCdnCookies = (courseIds) => {
    courseIds.forEach(courseId => {
        const cdbFlags = getCDNCookiesFlag(courseId);
        Cookies.remove('CloudFront-Policy', cdbFlags);
        Cookies.remove('CloudFront-Signature', cdbFlags);
        Cookies.remove('CloudFront-Key-Pair-Id', cdbFlags);
    });
}
